import prettyBytes from "pretty-bytes"
import { VideoDownloadFormatModeFrontEnd } from "../containers/HomePage/DownloadForm/VideoFormatForm/utils"
import { notUndefined } from "./general"
import ISO6391 from "iso-639-1"

export const useEllipsisIfNeeded = (text: string, maxLen: number): string => {
  if (text.length <= maxLen) return text
  return `${text.substring(0, maxLen - 3)}...`
}

export const videoDownloadFormatModeFrontEndText = (
  x: VideoDownloadFormatModeFrontEnd | undefined
) => {
  if (x === `audiovideo`) return `Audio & Video`
  else if (x === `audio`) return `Audio Only`
  else if (x === `video`) return `Video Only`
  else if (x === `merge`) return `Custom Audio & Video`
  else if (x === `subs`) return `Subtitles Only`
  else return undefined
}

export const videoDownloadStatusText = (x: VideoDownloadStatus) => {
  return `${x.charAt(0).toUpperCase()}${x.slice(1)}`
}

export const bytesText = (
  x: number | undefined,
  defaultIfUndefined: string | undefined = undefined
): string | undefined => {
  if (x === undefined) return defaultIfUndefined
  return prettyBytes(x)
}

export const reasonForDoubleFormatSettingsText = (
  x: ReasonForDoubleFormatSettings
): string => {
  if (x === `recommended`) return `Merged`
  else if (x === `userCustomSelected`) return `Custom Merged`
  else return `Unknown`
}

export const ytdlVideoDownloadFormatSettingsText = (
  x: YtdlVideoDownloadFormatSettings | undefined,
  showMode: boolean = true,
  showFileSize: boolean = true
): string | undefined => {
  if (x === undefined) return undefined
  return [
    showMode ? videoDownloadFormatModeFrontEndText(x.mode) : undefined,
    x.mode === `audio` ? undefined : x.resolution,
    showFileSize ? bytesText(x.fileSize) : undefined,
    x.fileExtension,
  ]
    .filter(notUndefined)
    .join(`, `)
}

export const subtitlesLangCodeText = (lang: LanguageCode): string => {
  const getLangCodeNameWithNativeIfDifferent = (
    lang: LanguageCode
  ): string | undefined => {
    if (ISO6391.validate(lang)) {
      const name = ISO6391.getName(lang)
      const nativeName = ISO6391.getNativeName(lang)
      if (name !== nativeName) return `${nativeName} ${name}`
      return name
    }
    return undefined
  }
  const langCodeName = getLangCodeNameWithNativeIfDifferent(lang)
  if (langCodeName) {
    return `${lang} (${langCodeName})`
  }
  return lang
}

export const ytdlVideoDownloadSubtitlesSettingsText = (
  x: YtdlVideoDownloadSubtitlesSettings,
  showMode: boolean = true,
  showFileExtension: boolean = true
): string => {
  const details = [
    subtitlesLangCodeText(x.languageCode),
    showFileExtension ? `Format: ${x.fileExtension}` : undefined,
  ]
    .filter(notUndefined)
    .join(`, `)
  if (showMode) return `Subtitles: ${details}`
  return details
}

export const ytdlAudioOutputFormatText = (x: YtdlAudioOutputFormat): string => {
  if (x === "best") {
    return "Best Available"
  }
  return x
}
