import React from "react"
import { Form } from "react-bootstrap"
import { YtdlAudioOutputFormats } from "../../../../../../consts"
import { ytdlAudioOutputFormatText } from "../../../../../../utils/text"

const AudioOutputFormatForm = (props: {
  disabled: boolean
  handleAudioOutputFormatChange: (e: any) => any
}) => {
  const { handleAudioOutputFormatChange, disabled } = props
  return (
    <>
      <h4 style={{ marginTop: `1em`, fontWeight: 600 }}>Output Format</h4>
      <Form style={{ maxWidth: 500, margin: `auto` }}>
        <Form.Group>
          <Form.Control
            as="select"
            onChange={handleAudioOutputFormatChange}
            disabled={disabled}
            defaultValue={YtdlAudioOutputFormats[0]}
          >
            {YtdlAudioOutputFormats.map((f, i) => {
              return (
                <option key={i} value={f}>
                  {ytdlAudioOutputFormatText(f)}
                </option>
              )
            })}
          </Form.Control>
        </Form.Group>
      </Form>
    </>
  )
}

export default AudioOutputFormatForm
