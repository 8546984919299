import React from "react"
import { Spinner } from "react-bootstrap"

const StartDownloadButton = (props: {
  btnLoading: boolean
  btnDisabled: boolean
  handleStart: () => any
}) => {
  const { btnLoading, btnDisabled, handleStart } = props

  return (
    <div style={{ marginTop: `1em` }}>
      <h4 style={{ fontWeight: 600 }}>Standard Download</h4>
      {btnLoading ? (
        <button className="button button--lg disabled button--success">
          <Spinner
            as="span"
            role="status"
            aria-hidden="true"
            size="sm"
            animation="border"
            variant="light"
            style={{ verticalAlign: `middle` }}
          />{" "}
          Loading...
        </button>
      ) : (
        <button
          disabled={btnDisabled}
          className="button button--lg button--success"
          onClick={handleStart}
        >
          Start
        </button>
      )}
    </div>
  )
}

export default StartDownloadButton
