export const getQueryVariable = (variable: string): string | undefined => {
  // https://stackoverflow.com/a/53079875/9723640
  const query = window.location.search.substring(1)
  // console.log(query) //"app=article&act=news_content&aid=160990"
  const vars = query.split("&")
  // console.log(vars) //[ 'app=article', 'act=news_content', 'aid=160990' ]

  for (const v of vars) {
    const pair = v.split(`=`)
    if (pair[0] == variable) {
      return pair[1]
    }
  }
  return undefined
}
