import React from "react"
import isEqual from "fast-deep-equal"
import { Form } from "react-bootstrap"
import { Table } from "react-bootstrap"
import {
  getFormatCodesFromVideoDownloadFormatSettings,
  VideoDownloadFormatModeFrontEnd,
} from "../../utils"
import VideoDownloadFormatSettingsDisplay from "../../../../../../components/VideoDownloadFormatSettingsDisplay/VideoDownloadFormatSettingsDisplay"
import styles from "./VideoFormatSelectionForm.module.css"
import {
  isAudioOnlyFormatSettings,
  isDoubleFormatSettings,
} from "../../../../../../utils/video-format-settings"
import FastDownloadTooltip from "../../../../../../components/FastDownloadTooltip/FastDownloadTooltip"

const isFormatSelected = (
  mode: VideoDownloadFormatModeFrontEnd,
  selectedFormatSettings: VideoDownloadFormatSettings | undefined,
  f: VideoDownloadFormatSettings
): boolean => {
  if (selectedFormatSettings === undefined) return false
  const selectedFormatSettingsFormatCodes = getFormatCodesFromVideoDownloadFormatSettings(
    selectedFormatSettings
  )
  const currentFormatCodes = getFormatCodesFromVideoDownloadFormatSettings(f)
  if (mode !== `merge`) {
    return isEqual(selectedFormatSettingsFormatCodes, currentFormatCodes)
  }
  // in merge mode, need to see if in selectedFormatSettings the formatCodes include f's
  if (currentFormatCodes.length === 1) {
    return selectedFormatSettingsFormatCodes.includes(currentFormatCodes[0])
  }
  return false
}

const VideoFormatSelectionForm = (props: {
  mode: VideoDownloadFormatModeFrontEnd
  formats: VideoDownloadFormatSettings[]
  selectedFormatSettings: VideoDownloadFormatSettings | undefined
  handleSelectFormat: (format: VideoDownloadFormatSettings) => any
  disabled: boolean
}) => {
  const {
    mode,
    formats,
    selectedFormatSettings,
    handleSelectFormat,
    disabled,
  } = props
  return (
    <div className={styles.root}>
      <Table size="sm" style={{ display: `table` }}>
        <thead>
          <tr>
            <th>Format</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {formats.map((f, i) => {
            const shouldShowFastDownloadTooltip =
              mode !== `merge` && !isDoubleFormatSettings(f)
            return (
              <tr key={i}>
                <td>
                  <VideoDownloadFormatSettingsDisplay
                    videoDownloadFormatSettings={f}
                    isNotFormatForm={false}
                    showRecommended={true}
                  />
                  {shouldShowFastDownloadTooltip && (
                    <>
                      {" "}
                      {/* if it's an audio only type, show only `best` can give Fast Download. */}
                      <FastDownloadTooltip
                        message={`Supports fast download${
                          isAudioOnlyFormatSettings(f)
                            ? ` (Only with "Best Available" output format)`
                            : ``
                        }`}
                      />
                    </>
                  )}
                </td>
                <td>
                  <Form.Check
                    onChange={(e) => {
                      if (e.target.value == `on`) handleSelectFormat(f)
                    }}
                    checked={isFormatSelected(mode, selectedFormatSettings, f)}
                    type="radio"
                    id={i.toString()}
                    aria-label={i.toString()}
                    disabled={disabled}
                  />
                </td>
              </tr>
            )
          })}
        </tbody>
      </Table>
    </div>
  )
}

export default VideoFormatSelectionForm
