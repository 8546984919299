import React, { useState } from "react"
import { DOWNLOADASAUR } from "../../../consts"
import VideoFormatForm from "./VideoFormatForm/VideoFormatForm"
import URLForm from "./URLForm/URLForm"

import styles from "./DownloadForm.module.css"

const DownloadForm = () => {
  const [videoUrl, setVideoUrl] = useState<string | undefined>(undefined)
  return (
    <div className={styles.root}>
      <div className="container text--center">
        <h1 style={{ marginBottom: `0.5em` }}>
          {DOWNLOADASAUR} Online Video Downloader
        </h1>
      </div>
      <div className="container text--center">
        <URLForm setUrl={setVideoUrl} />
      </div>
      {videoUrl && (
        <div className="container text--center" style={{ marginTop: `1em` }}>
          <VideoFormatForm videoUrl={videoUrl} />
        </div>
      )}
    </div>
  )
}

export default DownloadForm
