import * as React from "react"
import { Link } from "react-router-dom"
import undrawFileSync from "../../../assets/HomePage/undraw_File_sync_re_0pcx.svg"
import undrawOnlineVideo from "../../../assets/HomePage/undraw_online_video_ivvq.svg"
import undrawVideoFiles from "../../../assets/HomePage/undraw_video_files_fu10.svg"
import undrawDevices from "../../../assets/HomePage/undraw_Devices_re_dxae.svg"
import undrawDownload from "../../../assets/HomePage/undraw_download_pc33.svg"
import { DOWNLOADASAUR, endpoints } from "../../../consts"
import styles from "../HomePage.module.css"

const WhyDownloadasaur = () => {
  return (
    <div className="container text--center">
      <h1>Why use {DOWNLOADASAUR}?</h1>
      <div className="row">
        <div className="col col--4" style={{ marginTop: `1em` }}>
          <img
            alt="Download From 100s of sites"
            className={`${styles.featureImage} hoverLarge`}
            src={undrawVideoFiles}
          />
          <h2 className={styles.featureHeading}>
            Download from <span className="highlightColorLight">1000+</span>{" "}
            video and audio sites
          </h2>
          <p className="padding-horiz--md">
            {DOWNLOADASAUR} can download videos from many websites, including
            YouTube, Facebook, Twitter, Instagram and more! See{" "}
            <Link to={endpoints.routes.faq}>
              <span style={{ color: `#f1c40f` }}>the FAQ</span>
            </Link>{" "}
            for more info.
          </p>
        </div>
        <div className="col col--4" style={{ marginTop: `1em` }}>
          <img
            className={`${styles.featureImage} hoverLarge`}
            alt="Simple To Use"
            src={undrawFileSync}
          />
          <h2 className={styles.featureHeading}>
            <span className="highlightColorLight">Simple</span> To Use
          </h2>
          <p className="padding-horiz--md">
            Simply paste the video's link, tweak some settings, then sit back
            and relax! {DOWNLOADASAUR} is simple to use, no matter on desktop or
            mobile.
          </p>
        </div>
        <div className="col col--4" style={{ marginTop: `1em` }}>
          <img
            alt="Wide range of Qualities"
            className={`${styles.featureImage} hoverLarge`}
            src={undrawOnlineVideo}
          />
          <h2 className={styles.featureHeading}>
            Wide range of <span className="highlightColorLight">Qualities</span>
          </h2>
          <p className="padding-horiz--md">
            Choose from a wide range of video qualities! You can also choose to
            only download individual audio and video streams from the video.
          </p>
        </div>
      </div>
      <div className="row">
        <div className="col col--offset-2 col--4" style={{ marginTop: `1em` }}>
          <img
            alt="Mix, Match and Convert"
            className={`${styles.featureImage} hoverLarge`}
            src={undrawDownload}
          />
          <h2 className={styles.featureHeading}>
            Mix, Match and <span className="highlightColorLight">Convert</span>
          </h2>
          <p className="padding-horiz--md">
            Choose audio and video streams of different qualities, then
            automatically merge and convert them into a format of your liking!
          </p>
        </div>

        <div className="col col--4" style={{ marginTop: `1em` }}>
          <img
            alt="Download from Anywhere"
            className={`${styles.featureImage} hoverLarge`}
            src={undrawDevices}
          />
          <h2 className={styles.featureHeading}>
            Download from <span className="highlightColorLight">Anywhere</span>
          </h2>
          <p className="padding-horiz--md">
            {DOWNLOADASAUR} download links are live for up to 24 hours--you can
            download as many times as you like on as many devices as you want!
          </p>
        </div>
      </div>
    </div>
  )
}

export default WhyDownloadasaur
