import React, { useState, useEffect, useRef } from "react"
import { useLocalState } from "../../../contexts/localState"
import { Table } from "react-bootstrap"
import styles from "../HomePage.module.css"
import { FirestoreUnsubscribeHandler } from "../../../utils/firebase/firebase"
import { extractTableContents, TableContent } from "./utils"
import TableEntry from "./TableEntry"
import {
  subscribeVideoInfosForUserID,
  VideoDownloadFirestoreWithID,
} from "../../../utils/firebase/firestore"

const PreviousDownloads = () => {
  const [localState, setLocalState] = useLocalState()
  const { userID } = localState
  const [tableContents, setTableContents] = useState<TableContent[]>([])
  const unsubscribeRef = useRef<FirestoreUnsubscribeHandler | undefined>(
    undefined
  )

  useEffect(() => {
    if (userID === undefined) {
      setTableContents([])
    } else {
      unsubscribeRef.current = subscribeVideoInfosForUserID(
        userID,
        (x: VideoDownloadFirestoreWithID[]) => {
          const tcs = extractTableContents(x)
          setTableContents(tcs)
        }
      )
    }
    return () => {
      if (unsubscribeRef.current) {
        unsubscribeRef.current()
        unsubscribeRef.current = undefined
      }
    }
  }, [userID])

  if (tableContents.length === 0) {
    return <></>
  }
  return (
    <div
      className={`${styles.root} ${styles.section} ${styles.previousDownloads}`}
    >
      <div className="text--center container">
        <h1 style={{ marginBottom: `0.5em` }}>Downloads</h1>
        <Table
          hover
          size="sm"
          variant="dark"
          style={{
            backgroundColor: `black`,
            width: `100%`,
          }}
        >
          <thead>
            <tr>
              <th>Video</th>
              <th>Format</th>
              <th>Status</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {tableContents.map((tableContent, i) => (
              <TableEntry key={i} tableContent={tableContent} />
            ))}
          </tbody>
        </Table>
      </div>
    </div>
  )
}

export default PreviousDownloads
