import React, { useState } from "react"
import { endpoints, genericErrorMessage, queryVariables } from "../../../consts"
import {
  useEllipsisIfNeeded,
  videoDownloadStatusText,
} from "../../../utils/text"
import { videoDownloadStatusColour } from "../../../utils/colours"
import { TableContent } from "./utils"
import { Spinner } from "react-bootstrap"
import { removeDownloadID } from "../../../utils/firebase/api"
import { message } from "antd"
import VideoDownloadFormatSettingsDisplay from "../../../components/VideoDownloadFormatSettingsDisplay/VideoDownloadFormatSettingsDisplay"
import { Link } from "react-router-dom"
import DownloadVideoButton from "../../../components/DownloadVideoButton/DownloadVideoButton"

const TableEntry = (props: { tableContent: TableContent }) => {
  const { tableContent } = props
  const {
    downloadID,
    videoTitle,
    downloadStatus,
    githubActionRunID,
    formatSettings,
  } = tableContent
  const [removeDisabled, setRemoveDisabled] = useState(false)

  const removeDownload = async () => {
    setRemoveDisabled(true)
    try {
      await removeDownloadID({ downloadID: downloadID })
    } catch (err) {
      console.error(err)
      message.error(genericErrorMessage)
    }
    setRemoveDisabled(false)
  }

  const statusActionMap: Map<VideoDownloadStatus, JSX.Element> = new Map([
    ["initialising", <Spinner animation="border" variant="light" />],
    ["processing", <Spinner animation="border" variant="light" />],
    [
      "finished",
      githubActionRunID === undefined ? (
        <Spinner animation="border" variant="light" />
      ) : (
        <DownloadVideoButton
          downloadID={downloadID}
          githubActionRunID={githubActionRunID}
          videoTitle={videoTitle}
          large={false}
        />
      ),
    ],
    [
      "expired",
      <>
        {removeDisabled ? (
          <button className="button disabled button--warning">
            <Spinner
              as="span"
              role="status"
              aria-hidden="true"
              size="sm"
              animation="border"
              variant="light"
              style={{ verticalAlign: `middle` }}
            />{" "}
            Loading...
          </button>
        ) : (
          <button onClick={removeDownload} className="button button--warning">
            Remove
          </button>
        )}
      </>,
    ],
    [
      "error",
      <Link
        to={`${endpoints.routes.download}?${queryVariables.downloadID}=${downloadID}`}
      >
        <button className="button button--secondary">Details</button>
      </Link>,
    ],
  ])

  const actionComponent = statusActionMap.get(downloadStatus) ?? <></>
  return (
    <tr key={downloadID}>
      <td>
        <Link
          to={`${endpoints.routes.download}?${queryVariables.downloadID}=${downloadID}`}
        >
          {useEllipsisIfNeeded(videoTitle, 120)}
        </Link>
      </td>
      <td>
        <VideoDownloadFormatSettingsDisplay
          videoDownloadFormatSettings={formatSettings}
          isNotFormatForm={true}
          showRecommended={false}
        />
      </td>
      <td>
        {
          <span style={{ color: videoDownloadStatusColour(downloadStatus) }}>
            {videoDownloadStatusText(downloadStatus)}
          </span>
        }
      </td>
      <td>{actionComponent}</td>
    </tr>
  )
}

export default TableEntry
