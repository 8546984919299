import React from "react"
import { Route, Switch } from "react-router-dom"
import HomePage from "../../HomePage/HomePage"
import { endpoints, IS_PRODUCTION } from "../../../consts"
import PrivacyPolicy from "../../PrivacyPolicy/PrivacyPolicy"
import TermsOfService from "../../TermsOfService/TermsOfService"
import FaqPage from "../../FaqPage/FaqPage"
import ErrorComponent from "../../../components/ErrorComponent/ErrorComponent"
import DownloadIDPage from "../../DownloadIDPage/DownloadIDPage"
import Playground from "../../Playground/Playground"

const AppContent = () => {
  return (
    <Switch>
      <Route path="/" exact component={HomePage} />
      <Route path={endpoints.routes.download} component={DownloadIDPage} />
      <Route path={endpoints.routes.faq} exact component={FaqPage} />
      <Route
        path={endpoints.routes.privacyPolicy}
        exact
        component={PrivacyPolicy}
      />
      <Route
        path={endpoints.routes.termsOfService}
        exact
        component={TermsOfService}
      />
      {!IS_PRODUCTION && (
        <Route
          path={endpoints.routes.playground}
          exact
          component={Playground}
        />
      )}
      <Route
        component={() => (
          <ErrorComponent error="We couldn't find the item you're looking for - are you sure you've got the right link?" />
        )}
      />
    </Switch>
  )
}

export default AppContent
