import React from "react"
import { message } from "antd"
import { Button, FormControl, InputGroup } from "react-bootstrap"

const CopyToClipboard = (props: { text: string }) => {
  const { text } = props
  return (
    <InputGroup>
      <FormControl value={text} disabled={true} />
      <InputGroup.Append>
        <Button
          variant="dark"
          onClick={() => {
            navigator.clipboard.writeText(text)
            message.info(`Copied to clipboard!`)
          }}
        >
          Copy to Clipboard
        </Button>
      </InputGroup.Append>
    </InputGroup>
  )
}

export default CopyToClipboard
