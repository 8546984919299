export const IS_PRODUCTION = process.env.NODE_ENV === `production`
export const DOWNLOADASAUR = `Downloadasaur`
export const CONTACT_EMAIL = `claperone.downloadasaur@gmail.com`

export const CLAPERONE = "Claperone"
export const CLAPERTWO = "Clapertwo"
export const currentYear = new Date().getFullYear()

export const queryVariables = {
  // HomePage
  videoUrl: `video_url`,
  // DownloadIDPage
  downloadID: `download_id`,
}

export const YtdlMergeOutputFormats: YtdlMergeOutputFormat[] = [
  "mp4",
  "mkv",
  "ogg",
  "webm",
  "flv",
]

export const YtdlAudioOutputFormats: YtdlAudioOutputFormat[] = [
  "best",
  "aac",
  "flac",
  "mp3",
  "m4a",
  "opus",
  "vorbis",
  "wav",
]
export const firebaseConsts = {
  firestore: {
    downloadsCollection: IS_PRODUCTION
      ? `video-downloads`
      : `dev-video-downloads`,
  },
  emulatorPorts: {
    auth: 9099,
    functions: 5001,
    firestore: 8080,
  },
}

export const SERVER_URL = IS_PRODUCTION
  ? `https://us-central1-downloadasaur.cloudfunctions.net/api`
  : `http://localhost:${firebaseConsts.emulatorPorts.functions}/downloadasaur/us-central1/api`

export const endpoints = {
  routes: {
    privacyPolicy: "/privacy-policy",
    termsOfService: "/terms-of-service",
    faq: "/faq",
    download: "/download",
    playground: "/playground",
  },
  api: {
    downloadableVideoFormats: `${SERVER_URL}/downloadable_video_formats`,
    downloadVideo: `${SERVER_URL}/download_video`,
    expireDownloadID: `${SERVER_URL}/expire_download_id`,
    getArtifact: `${SERVER_URL}/get_artifact`,
    removeDownloadID: `${SERVER_URL}/remove_download_id`,
  },
}

export const genericErrorMessage = `Something went wrong. Please try again later.`

export const DOWNLOADER_REPO = IS_PRODUCTION
  ? "downloadasaur-downloader"
  : "downloadasaur-downloader-dev"
