import React from "react"
import { Tooltip } from "antd"
import { InfoCircleOutlined } from "@ant-design/icons"
import styles from "./InfoTooltip.module.css"

const InfoTooltip = (props: { info: string | undefined }) => {
  const { info } = props
  if (info === undefined || info.length === 0) {
    return <></>
  }
  return (
    <span className={styles.root}>
      <Tooltip title={info} className="hoverLarge">
        <InfoCircleOutlined />
      </Tooltip>
    </span>
  )
}

export default InfoTooltip
