import React, { useState, useEffect, useRef } from "react"
import ErrorComponent from "../../components/ErrorComponent/ErrorComponent"
import Loading from "../../components/Loading/Loading"
import { Link } from "react-router-dom"
import { videoDownloadStatusColour } from "../../utils/colours"
import { getQueryVariable } from "../../utils/query"
import { videoDownloadStatusText } from "../../utils/text"
import styles from "./DownloadIDPage.module.css"
import { endpoints, genericErrorMessage, queryVariables } from "../../consts"
import { FirestoreUnsubscribeHandler } from "../../utils/firebase/firebase"
import VideoDownloadFormatSettingsDisplay from "../../components/VideoDownloadFormatSettingsDisplay/VideoDownloadFormatSettingsDisplay"
import { errorCodes, errorCodeMsgMap } from "../../utils/errors"
import loading from "../../assets/Loading/loading.svg"
import DownloadVideoButton from "../../components/DownloadVideoButton/DownloadVideoButton"
import { subscribeDownloadID } from "../../utils/firebase/firestore"
import VideoThumbnail from "../../components/VideoThumbnail/VideoThumbnail"
import CopyToClipboard from "../../components/CopyToClipboard/CopyToClipboard"

const StatusComponent = (props: {
  downloadID: string
  videoInfo: VideoDownloadFirestore
}) => {
  const { videoInfo, downloadID } = props
  const {
    errorCode,
    errorMsg,
    notificationEmail,
    videoUrl,
    downloadStatus,
    githubActionRunID,
    videoTitle,
  } = videoInfo

  if (
    downloadStatus === `initialising` ||
    downloadStatus === `processing` ||
    githubActionRunID === undefined
  ) {
    return (
      <>
        <div>
          <p>
            We're hard at work preparing your download, come back here (or check
            the{" "}
            <Link to="/" style={{ color: `#f1c40f` }}>
              home page
            </Link>
            ) later.
            {notificationEmail && notificationEmail.length > 0 && (
              <>{` An email will be sent to "${notificationEmail}" when the download is complete.`}</>
            )}
          </p>
          <div
            style={{
              maxWidth: `min(100%, 800px)`,
              margin: `auto`,
              marginBottom: `1em`,
            }}
          >
            <CopyToClipboard text={window.location.href} />
          </div>
        </div>
        <img src={loading} alt="loading" />
      </>
    )
  } else if (downloadStatus === `finished`) {
    return (
      <DownloadVideoButton
        downloadID={downloadID}
        githubActionRunID={githubActionRunID}
        videoTitle={videoTitle}
        large={true}
      />
    )
  } else if (downloadStatus === `expired`) {
    return (
      <>
        We only keep your downloads up to 24 hours, but you can{" "}
        <Link
          to={`/?${queryVariables.videoUrl}=${videoUrl}`}
          style={{ color: `#f1c40f` }}
        >
          download again!
        </Link>
      </>
    )
  } else {
    // error
    const errorStr =
      errorCodeMsgMap.get(errorCode ?? errorCodes.UNKNOWN_ERROR) ??
      genericErrorMessage
    console.error(`Error code: ${errorCode}, Error message: ${errorMsg}`)
    return (
      <div className="alert alert--danger" role="alert">
        {errorStr}. Check the{" "}
        <Link to={endpoints.routes.faq}>
          <span style={{ color: `#f1c40f` }}>the FAQ</span>
        </Link>{" "}
        for more info.
      </div>
    )
  }
}

const DownloadIDPage = () => {
  const downloadID = getQueryVariable(queryVariables.downloadID)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState<string | undefined>(undefined)
  const [videoInfo, setVideoInfo] = useState<
    VideoDownloadFirestore | undefined
  >(undefined)
  const unsubscribe = useRef<FirestoreUnsubscribeHandler | undefined>(undefined)

  useEffect(() => {
    if (downloadID === undefined) {
      setError(
        `No download ID supplied--are you sure you've got the right link?`
      )
      return
    }

    unsubscribe.current = subscribeDownloadID(
      downloadID,
      (x: VideoDownloadFirestore) => {
        setVideoInfo(x)
        setLoading(false)
      },
      setError
    )

    return () => {
      if (unsubscribe.current) {
        unsubscribe.current()
        unsubscribe.current = undefined
      }
    }
  }, [])

  if (error || downloadID === undefined) {
    return <ErrorComponent error={error} />
  } else if (loading || videoInfo === undefined) {
    return <Loading />
  }
  const {
    videoTitle,
    videoUrl,
    videoThumbnail,
    formatSettings,
    downloadStatus,
  } = videoInfo

  return (
    <div className={styles.root} style={{ padding: "32px 0" }}>
      <div className="container text--center">
        <a target="_blank" rel="noopener noreferrer" href={videoUrl}>
          <h1>{videoTitle}</h1>
        </a>
        <VideoThumbnail
          videoThumbnail={videoThumbnail}
          videoTitle={videoTitle}
          videoUrl={videoUrl}
        />
        <h2 style={{ fontWeight: 500, marginTop: `0.5em` }}>
          <VideoDownloadFormatSettingsDisplay
            videoDownloadFormatSettings={formatSettings}
            isNotFormatForm={true}
            showRecommended={false}
          />
        </h2>
        <h3 style={{ fontWeight: 400 }}>
          Status:{" "}
          <span style={{ color: videoDownloadStatusColour(downloadStatus) }}>
            {videoDownloadStatusText(downloadStatus)}
          </span>
        </h3>
        <StatusComponent videoInfo={videoInfo} downloadID={downloadID} />
      </div>
    </div>
  )
}

export default DownloadIDPage
