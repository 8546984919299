import React from "react"
import styles from "./HomePage.module.css"
import WhyDownloadasaur from "./WhyDownloadasaur/WhyDownloadasaur"
import PreviousDownloads from "./PreviousDownloads/PreviousDownloads"
import DownloadForm from "./DownloadForm/DownloadForm"

const HomePage = () => {
  return (
    <div className={styles.root}>
      <DownloadForm />
      <PreviousDownloads />
      <div className={styles.section}>
        <WhyDownloadasaur />
      </div>
    </div>
  )
}

export default HomePage
