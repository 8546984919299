import React, { useState } from "react"
import * as yup from "yup"
import { Formik } from "formik"
import { Form } from "react-bootstrap"
import { queryVariables } from "../../../../consts"
import { getQueryVariable } from "../../../../utils/query"

const URLForm = (props: {
  setUrl: React.Dispatch<React.SetStateAction<string | undefined>>
}) => {
  const [formDisabled, setFormDisabled] = useState(false)
  const { setUrl } = props
  return (
    <Formik
      validationSchema={yup.object({
        url: yup.string().url(`Please enter a valid URL`),
      })}
      onSubmit={async (vals) => {
        setFormDisabled(true)
        const { url } = vals
        setUrl(url)
      }}
      enableReinitialize
      initialValues={{ url: getQueryVariable(queryVariables.videoUrl) ?? `` }}
    >
      {({
        handleSubmit,
        handleChange,
        handleReset,
        values,
        touched,
        isValid,
        errors,
      }) => (
        <Form noValidate onSubmit={handleSubmit}>
          <Form.Group controlId="url">
            <Form.Control
              type="text"
              name="url"
              value={values.url}
              placeholder="Enter Video URL"
              onChange={handleChange}
              isInvalid={!!errors.url}
              isValid={touched.url && !errors.url}
              readOnly={formDisabled}
            />
            <Form.Control.Feedback type="invalid" style={{ color: `white` }}>
              {errors.url}
            </Form.Control.Feedback>
          </Form.Group>
          {formDisabled ? (
            <button
              type="button"
              className={`button button--secondary`}
              style={{ fontSize: `1em` }}
              onClick={() => {
                handleReset()
                setFormDisabled(false)
                setUrl(undefined)
              }}
            >
              Reset
            </button>
          ) : (
            <button
              type="submit"
              disabled={!values.url.length || !isValid}
              className={`button button--secondary`}
              style={{ fontSize: `1em` }}
            >
              Go
            </button>
          )}
        </Form>
      )}
    </Formik>
  )
}

export default URLForm
