import React from "react"
import {
  ytdlVideoDownloadFormatSettingsText,
  subtitlesLangCodeText,
  ytdlVideoDownloadSubtitlesSettingsText,
} from "../../utils/text"
import {
  isDoubleFormatSettings,
  isSingleFormatSettings,
  isAudioOnlyFormatSettings,
} from "../../utils/video-format-settings"
import InfoTooltip from "../InfoTooltip/InfoTooltip"

const VideoSubtitlesDisplay = (props: {
  videoSubtitlesSettings: YtdlVideoDownloadSubtitlesSettings
  isNotFormatForm: boolean
}) => {
  const { videoSubtitlesSettings, isNotFormatForm } = props
  return (
    <>
      {ytdlVideoDownloadSubtitlesSettingsText(
        videoSubtitlesSettings,
        isNotFormatForm,
        isNotFormatForm
      )}
    </>
  )
}

const VideoDownloadFormatSettingsDisplay = (props: {
  videoDownloadFormatSettings: VideoDownloadFormatSettings | undefined
  isNotFormatForm: boolean
  showRecommended: boolean
}) => {
  const {
    videoDownloadFormatSettings,
    isNotFormatForm,
    showRecommended,
  } = props

  const getSubtitlesComponent = () => {
    return (
      <>
        {videoDownloadFormatSettings &&
          `videoSubtitlesSettings` in videoDownloadFormatSettings &&
          videoDownloadFormatSettings.videoSubtitlesSettings &&
          isNotFormatForm && (
            <>
              {", "}
              <VideoSubtitlesDisplay
                videoSubtitlesSettings={
                  videoDownloadFormatSettings.videoSubtitlesSettings
                }
                isNotFormatForm={true} // show more info
              />
            </>
          )}{" "}
      </>
    )
  }

  if (videoDownloadFormatSettings === undefined) {
    return <></>
  } else if (isSingleFormatSettings(videoDownloadFormatSettings)) {
    // single
    return (
      <>
        {ytdlVideoDownloadFormatSettingsText(
          videoDownloadFormatSettings.ytdlFormatSettings,
          isNotFormatForm
        )}{" "}
        <InfoTooltip
          info={videoDownloadFormatSettings.ytdlFormatSettings.formatNote}
        />
        {getSubtitlesComponent()}
      </>
    )
  } else if (isDoubleFormatSettings(videoDownloadFormatSettings)) {
    // double
    return (
      <>
        {isNotFormatForm && `Audio & Video (Merged), `}
        Video:{" "}
        {ytdlVideoDownloadFormatSettingsText(
          videoDownloadFormatSettings.ytdlVideoFormatSettings,
          false,
          false
        )}{" "}
        <InfoTooltip
          info={videoDownloadFormatSettings.ytdlVideoFormatSettings?.formatNote}
        />
        {", "}
        Audio:{" "}
        {ytdlVideoDownloadFormatSettingsText(
          videoDownloadFormatSettings.ytdlAudioFormatSettings,
          false,
          false
        )}{" "}
        <InfoTooltip
          info={videoDownloadFormatSettings.ytdlAudioFormatSettings?.formatNote}
        />
        {", Output Format: "}
        {videoDownloadFormatSettings.mergeOutputFormat}
        {showRecommended && (
          <>
            {" "}
            <InfoTooltip info="Recommended" />
          </>
        )}
        {getSubtitlesComponent()}
      </>
    )
  } else if (isAudioOnlyFormatSettings(videoDownloadFormatSettings)) {
    return (
      <>
        {videoDownloadFormatSettings.ytdlFormatSettings.mode === `audio` ? (
          <>
            {ytdlVideoDownloadFormatSettingsText(
              videoDownloadFormatSettings.ytdlFormatSettings,
              isNotFormatForm
            )}
          </>
        ) : (
          <>From best Audio+Video stream</>
        )}{" "}
        <InfoTooltip
          info={videoDownloadFormatSettings.ytdlFormatSettings.formatNote}
        />
        {isNotFormatForm && (
          <>
            {", Output Format: "}
            {videoDownloadFormatSettings.audioOutputFormat}
          </>
        )}
        {getSubtitlesComponent()}
      </>
    )
  }
  // isSubtitlesOnlyFormatSettings
  return (
    <VideoSubtitlesDisplay
      videoSubtitlesSettings={
        videoDownloadFormatSettings.videoSubtitlesSettings
      }
      isNotFormatForm={isNotFormatForm}
    />
  )
}
export default VideoDownloadFormatSettingsDisplay
