import React, { createRef, useEffect } from "react"
import { message } from "antd"
import { Form } from "react-bootstrap"
import InfoTooltip from "../../../../../components/InfoTooltip/InfoTooltip"
import {
  getSubWithBestFileExtensionFromLangCode,
  LanguageCodeWithNone,
} from "./utils"
import { subtitlesLangCodeText } from "../../../../../utils/text"
import { Typeahead } from "react-bootstrap-typeahead"
import styles from "./SubtitleForm.module.css"
import { genericErrorMessage } from "../../../../../consts"

const SubtitleForm = (props: {
  videoSubtitles: VideoSubtitles | undefined
  selectedVideoSubtitles: YtdlVideoDownloadSubtitlesSettings | undefined
  setSelectedVideoSubtitles: React.Dispatch<
    React.SetStateAction<YtdlVideoDownloadSubtitlesSettings | undefined>
  >
  disabled: boolean
}) => {
  const typeAheadRef = createRef<Typeahead<any>>()
  const {
    videoSubtitles,
    selectedVideoSubtitles,
    setSelectedVideoSubtitles,
    disabled,
  } = props
  const allLangCodes: LanguageCode[] = videoSubtitles
    ? Object.keys(videoSubtitles)
    : []
  const allLangCodesWithNone: LanguageCodeWithNone[] = ["None", ...allLangCodes]

  useEffect(() => {
    if (!selectedVideoSubtitles && typeAheadRef.current) {
      typeAheadRef.current.clear()
    }
  }, [selectedVideoSubtitles])

  const handleLangCodeChange = (
    ls: { id: LanguageCodeWithNone; name: string }[]
  ) => {
    try {
      if (ls.length > 0) {
        const l0 = ls[0]
        const l = l0.id
        if (l === "None") {
          setSelectedVideoSubtitles(undefined)
        } else {
          const subs = getSubWithBestFileExtensionFromLangCode(
            l,
            videoSubtitles
          )
          if (subs === undefined) {
            throw new Error(`Can't get best available subs`)
          }
          setSelectedVideoSubtitles(subs)
        }
      } else {
        // no-op
      }
    } catch (err) {
      console.error(err)
      message.error(genericErrorMessage)
    }
  }

  const handleExtChange = (e: any) => {
    const ext: FileExtension = e.target.value
    if (selectedVideoSubtitles === undefined) {
      // no-op
      console.debug(`Cant't set extension when langcode not set!`)
      return
    }
    setSelectedVideoSubtitles({
      ...selectedVideoSubtitles,
      fileExtension: ext,
    })
  }

  if (allLangCodes.length === 0) {
    return <></>
  }
  return (
    <div style={{ marginTop: `1em` }}>
      <h4 style={{ fontWeight: 600 }}>
        Subtitles{" "}
        <InfoTooltip info={`Subtitles will be included in a separate file.`} />
      </h4>
      <Form style={{ maxWidth: 300, margin: `auto` }}>
        <Form.Group>
          <span className={styles.typeahead}>
            <Typeahead
              ref={typeAheadRef}
              id="subtitle-lang"
              labelKey="name"
              onChange={handleLangCodeChange}
              options={allLangCodesWithNone.map((l, i) => {
                return { id: l, name: subtitlesLangCodeText(l) }
              })}
              placeholder="Choose a language..."
              flip
              disabled={disabled}
            />
          </span>
        </Form.Group>
        {selectedVideoSubtitles && (
          <>
            <Form.Group>
              <h4 style={{ fontWeight: 600 }}>Subtitle Format</h4>
              <Form.Control
                as="select"
                onChange={handleExtChange}
                disabled={disabled}
                value={selectedVideoSubtitles.fileExtension}
              >
                {(videoSubtitles &&
                selectedVideoSubtitles.languageCode in videoSubtitles
                  ? videoSubtitles[selectedVideoSubtitles.languageCode].map(
                      (x) => x.fileExtension
                    )
                  : []
                ).map((ext, i) => {
                  return (
                    <option key={i} value={ext} disabled={disabled}>
                      {ext}
                    </option>
                  )
                })}
              </Form.Control>
            </Form.Group>
          </>
        )}
      </Form>
    </div>
  )
}

export default SubtitleForm
