import { groupBy } from "../../../../../utils/general"

export type LanguageCodeWithNone = "None" | LanguageCode

export const getSubWithBestFileExtensionFromLangCode = (
  l: LanguageCode,
  allSubtitles: VideoSubtitles | undefined
): YtdlVideoDownloadSubtitlesSettings | undefined => {
  if (allSubtitles && l in allSubtitles) {
    const subs = allSubtitles[l]
    if (subs.length) {
      const extToSubsMap = groupBy(subs, (x) => x.fileExtension)
      // try srt first
      const srtSubs = extToSubsMap.get(`srt`) ?? []
      if (srtSubs.length) {
        return srtSubs[0]
      }
      // try vtt
      const vttSubs = extToSubsMap.get(`vtt`) ?? []
      if (vttSubs.length) {
        return vttSubs[0]
      }
      // return first available
      return subs[0]
    }
  }
}
