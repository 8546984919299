import * as React from "react"

import loading from "../../assets/Loading/loading.svg"

type Props = {
  loadingTitle?: string
  loadingText?: string | undefined
}

const defaultProps: Props = {
  loadingTitle: `Loading...`,
  loadingText: undefined,
}

const Loading: React.FC<Props> = (props: Props) => {
  return (
    <div style={{ margin: "auto" }} className="container text--center">
      <h1 style={{ paddingTop: 30, marginBottom: 0, fontSize: "3rem" }}>
        {props.loadingTitle}
      </h1>
      {props.loadingText && (
        <div style={{ padding: 20, fontSize: "1.5rem" }}>
          {props.loadingText}
        </div>
      )}
      <img src={loading} alt="loading" />
    </div>
  )
}
Loading.defaultProps = defaultProps

export default Loading
