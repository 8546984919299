import React from "react"
import { Spinner } from "react-bootstrap"
import InfoTooltip from "../InfoTooltip/InfoTooltip"

const FastDownloadVideoButton = (props: {
  btnLoading: boolean
  btnDisabled: boolean
  handleFastDownload: () => any
}) => {
  const { btnLoading, btnDisabled, handleFastDownload } = props

  return (
    <div style={{ marginTop: `1em` }}>
      <h4 style={{ fontWeight: 600 }}>
        Fast Download{" "}
        <InfoTooltip
          info={
            "Some simple formats support fast direct download. Some devices may not support this, so please use Standard Download if required. Also note that notification emails are not triggered."
          }
        />
      </h4>
      {btnLoading ? (
        <button className="button button--lg disabled button--warning">
          <Spinner
            as="span"
            role="status"
            aria-hidden="true"
            size="sm"
            animation="border"
            variant="light"
            style={{ verticalAlign: `middle` }}
          />{" "}
          Loading...
        </button>
      ) : (
        <button
          disabled={btnDisabled}
          className="button button--lg button--warning"
          onClick={handleFastDownload}
        >
          Fast Download
        </button>
      )}
    </div>
  )
}

export default FastDownloadVideoButton
