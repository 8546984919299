export const triggerDownload = async (
  url: string,
  fileName: string | undefined = undefined
) => {
  const a = document.createElement("a")
  a.href = url
  const fName = fileName ?? url.split(`/`).pop() ?? `download`
  a.setAttribute("download", fName)
  a.click()
}
