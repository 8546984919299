import React from "react"
import * as yup from "yup"
import { Formik } from "formik"
import { Form } from "react-bootstrap"
import InfoTooltip from "../../../../../components/InfoTooltip/InfoTooltip"

const NotificationEmailForm = (props: {
  setNotificationEmail: React.Dispatch<React.SetStateAction<string>>
  disabled: boolean
}) => {
  const { setNotificationEmail, disabled } = props
  return (
    <div style={{ marginTop: `1em` }}>
      <h4 style={{ fontWeight: 600 }}>
        Email Address for Notification{" "}
        <InfoTooltip
          info={`Enter your email address to be notified when your download is complete. Note that this email is not sent for downloads using the "Fast Download" mode.`}
        />
      </h4>
      <Formik
        validationSchema={yup.object({
          notificationEmail: yup.string().email(`Please enter a valid email`),
        })}
        enableReinitialize
        initialValues={{ notificationEmail: `` }}
        onSubmit={() => {}}
      >
        {({ handleChange, values, touched, errors }) => (
          <Form
            noValidate
            style={{ maxWidth: 300, margin: `auto` }}
            onSubmit={(e) => {
              e.preventDefault()
            }}
          >
            <Form.Group controlId="education">
              <Form.Control
                type="text"
                name="notificationEmail"
                value={values.notificationEmail}
                placeholder="Enter email address"
                onChange={(e) => {
                  handleChange(e)
                  setNotificationEmail(e.target.value)
                }}
                isInvalid={!!errors.notificationEmail}
                isValid={touched.notificationEmail && !errors.notificationEmail}
                disabled={disabled}
              />
              <Form.Control.Feedback type="invalid" style={{ color: `white` }}>
                {errors.notificationEmail}
              </Form.Control.Feedback>
            </Form.Group>
          </Form>
        )}
      </Formik>
    </div>
  )
}

export default NotificationEmailForm
