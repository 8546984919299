import { createContext, useReducer, useContext } from "react"
import isEqual from "fast-deep-equal"

export type LocalState = {
  userID: string | undefined
}

const LOCAL_STATE_LOCALSTORAGE_KEY = `localState`

export type LocalStateDispatchType = (values: LocalState) => void

const localStorageLocalState = window.localStorage.getItem(
  LOCAL_STATE_LOCALSTORAGE_KEY
)
const initialLocalState: LocalState = localStorageLocalState
  ? JSON.parse(localStorageLocalState)
  : { userID: undefined }

const writeLocalStateToLocalStorageIfRequired = async (
  state: LocalState,
  nextState: LocalState
) => {
  if (!isEqual(state, nextState)) {
    window.localStorage.setItem(
      LOCAL_STATE_LOCALSTORAGE_KEY,
      JSON.stringify(nextState)
    )
  }
}

const LocalStateContext = createContext(initialLocalState)
const DispatchLocalStateContext = createContext({} as LocalStateDispatchType)

export const LocalStateProvider = ({ children }: { children: any }) => {
  const [state, dispatch] = useReducer(
    (state: LocalState, newValue: LocalState) => {
      const nextState = { ...state, ...newValue }
      // only write back to localStorage async-ally if nextState is different
      writeLocalStateToLocalStorageIfRequired(state, nextState)
      return nextState
    },
    initialLocalState
  )
  return (
    <LocalStateContext.Provider value={state}>
      <DispatchLocalStateContext.Provider value={dispatch}>
        {children}
      </DispatchLocalStateContext.Provider>
    </LocalStateContext.Provider>
  )
}

export const useLocalState = (): [LocalState, LocalStateDispatchType] => {
  return [useContext(LocalStateContext), useContext(DispatchLocalStateContext)]
}
