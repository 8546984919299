import React, { useState } from "react"
import defaultVideoThumbnail from "../../assets/VideoThumbnail/default.png"
const VideoThumbnail = (props: {
  videoThumbnail: string | undefined
  videoTitle: string | undefined
  videoUrl: string
}) => {
  const { videoThumbnail, videoTitle, videoUrl } = props
  const [shownVideoThumbnail, setShownVideoThumbnail] = useState(
    videoThumbnail ?? defaultVideoThumbnail
  )

  return (
    <a target="_blank" rel="noopener noreferrer" href={videoUrl}>
      <img
        src={shownVideoThumbnail}
        alt={videoTitle}
        style={{ maxWidth: `min(100%, 500px)` }}
        onError={() => {
          if (shownVideoThumbnail !== defaultVideoThumbnail) {
            setShownVideoThumbnail(defaultVideoThumbnail)
          }
        }}
      />
    </a>
  )
}

export default VideoThumbnail
