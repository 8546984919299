import React from "react"
import { Tooltip } from "antd"
import styles from "./FastDownloadTooltip.module.css"

const FastDownloadTooltip = (props: { message: string }) => {
  const { message } = props
  return (
    <span className={styles.root}>
      <Tooltip title={message} className="hoverLarge">
        ⚡
      </Tooltip>
    </span>
  )
}

export default FastDownloadTooltip
