import React, { useState, useEffect } from "react"
import loadingSVG from "../../../../assets/Loading/loading-white.svg"
import {
  FormatMap,
  FastDownloadPayload,
  getFrontEndDownloadFormatSettings,
  VideoDownloadFormatModeFrontEnd,
  getNewSelectedFormatSettingsForMergeMode,
  getNewSelectedFormatSettingsOnMergeOutputFormatChange,
  getNewSelectedFormatSettingsOnAudioOutputFormatChange,
  prepareDownloadRequest,
  validateFinalSelectedFormatSettings,
  shouldShowSubtitlesModes,
  validateSelectedFormatSettingsForFastDownload,
  getFastDownloadPayloads,
} from "./utils"
import styles from "./VideoFormatForm.module.css"
import { history } from "../../../../utils/history"
import {
  endpoints,
  genericErrorMessage,
  queryVariables,
} from "../../../../consts"
import { message } from "antd"
import VideoFormatFormMode from "./VideoFormatFormMode/VideoFormatFormMode"
import { useLocalState } from "../../../../contexts/localState"
import {
  downloadableVideoFormats,
  downloadVideo,
} from "../../../../utils/firebase/api"
import NotificationEmailForm from "./NotificationEmailForm/NotificationEmailForm"
import VideoThumbnail from "../../../../components/VideoThumbnail/VideoThumbnail"
import { RateLimitExceededError } from "../../../../utils/errors"
import SubtitleForm from "./SubtitleForm/SubtitleForm"
import StartDownloadButton from "../../../../components/StartDownloadButton/StartDownloadButton"
import ModeSelect from "./ModeSelect/ModeSelect"
import FastDownloadVideoButton from "../../../../components/FastDownloadVideoButton/FastDownloadVideoButton"
import FastDownloadModal from "./FastDownloadModal/FastDownloadModal"
import {
  isBrowserSafari,
  isDeviceIPhone,
} from "../../../../utils/mobile-detect"

const VideoFormatForm = (props: { videoUrl: string }) => {
  const initialMode: VideoDownloadFormatModeFrontEnd = `audiovideo`

  const [loading, setLoading] = useState(true)
  const [standardDownloadLoading, setStandardDownloadLoading] = useState(false)
  const [fastDownloadLoading, setFastDownloadLoading] = useState(false)
  const [localState, setLocalState] = useLocalState()
  // info from payload
  const [videoTitle, setVideoTitle] = useState<string | undefined>(undefined)
  const [videoThumbnail, setVideoThumbnail] = useState<string | undefined>(
    undefined
  )
  const [formats, setFormats] = useState<FormatMap | undefined>(undefined)
  const [videoSubtitles, setVideoSubtitles] = useState<
    VideoSubtitles | undefined
  >(undefined)
  const [error, setError] = useState<string | undefined>(undefined)
  // selection
  const [mode, setMode] = useState<VideoDownloadFormatModeFrontEnd>(initialMode)
  const [selectedFormatSettings, setSelectedFormatSettings] = useState<
    VideoDownloadFormatSettings | undefined
  >(undefined)
  const [selectedVideoSubtitles, setSelectedVideoSubtitles] = useState<
    YtdlVideoDownloadSubtitlesSettings | undefined
  >(undefined) // we have to have this as separate as the logic to integrate this into selectedFormatSettings is annoying
  const [notificationEmail, setNotificationEmail] = useState(``)
  // fast download
  const [fastDownloadPayloads, setFastDownloadPayloads] = useState<
    FastDownloadPayload[]
  >([])

  useEffect(() => {
    const f = async () => {
      try {
        const { videoUrl } = props
        const payload = await downloadableVideoFormats(videoUrl)
        const formats = getFrontEndDownloadFormatSettings(payload.ytdlFormats)
        if (!formats.has(initialMode)) {
          // if initialMode is not available then set to the first one available, otherwise give up
          const availableModes = Array.from(formats.keys())
          if (availableModes.length) {
            setMode(availableModes[0])
          }
        }
        setVideoSubtitles(payload.videoSubtitles)
        setVideoTitle(payload.videoTitle)
        setVideoThumbnail(payload.videoThumbnail)
        setFormats(formats)

        setLoading(false)
      } catch (err) {
        console.error(err)
        const { videoUrl } = props
        setError(
          `Unable to get download information for "${videoUrl}"--it may be unsupported or copyrighted.`
        )
      }
    }
    f()
  }, [props.videoUrl])

  const resetSelections = async () => {
    setSelectedFormatSettings(undefined)
    setSelectedVideoSubtitles(undefined)
  }

  const handleModeChange = async (e: any) => {
    const newMode: VideoDownloadFormatModeFrontEnd = e.target.value
    setMode(newMode)
    resetSelections()
  }

  const handleMergeOutputFormatChange = async (e: any) => {
    try {
      const newOutputFormat: YtdlMergeOutputFormat = e.target.value
      const newSelectedFormatSettings = getNewSelectedFormatSettingsOnMergeOutputFormatChange(
        mode,
        selectedFormatSettings,
        newOutputFormat
      )
      setSelectedFormatSettings(newSelectedFormatSettings)
    } catch (err) {
      console.error(err)
      message.error(genericErrorMessage)
    }
  }

  const handleAudioOutputFormatChange = async (e: any) => {
    try {
      if (!formats) {
        throw new Error(`Formats is undefined`) // should not happen!
      }
      const audioOnlyFormats = formats.get(`audio`) ?? []
      const audioOutputFormat: YtdlAudioOutputFormat = e.target.value
      const newSelectedFormatSettings = getNewSelectedFormatSettingsOnAudioOutputFormatChange(
        mode,
        selectedFormatSettings,
        audioOutputFormat,
        audioOnlyFormats
      )
      setSelectedFormatSettings(newSelectedFormatSettings)
    } catch (err) {
      console.error(err)
      message.error(genericErrorMessage)
    }
  }

  const handleSelectFormat = async (format: VideoDownloadFormatSettings) => {
    if (mode !== `merge`) {
      // not a custom merge
      setSelectedFormatSettings(format)
    } else {
      // merge, so need to determine right place to assign
      try {
        const newSelectedFormatSettings = getNewSelectedFormatSettingsForMergeMode(
          selectedFormatSettings,
          format
        )
        setSelectedFormatSettings(newSelectedFormatSettings)
      } catch (err) {
        console.error(err)
        message.error(genericErrorMessage)
      }
    }
  }

  const handleStartStandardDownload = async () => {
    setStandardDownloadLoading(true)
    try {
      const req: DownloadVideoRequest = prepareDownloadRequest(
        mode,
        selectedFormatSettings,
        selectedVideoSubtitles,
        notificationEmail,
        videoTitle,
        videoThumbnail,
        videoUrl,
        localState.userID
      )
      const { downloadID, userID } = await downloadVideo(req)
      setLocalState({ ...localState, userID: userID })
      history.push(
        `${endpoints.routes.download}?${queryVariables.downloadID}=${downloadID}`
      )
    } catch (err) {
      console.error(err)
      if (err?.response?.status === RateLimitExceededError.code) {
        message.error(
          `You've downloaded too many videos today - come back tomorrow to download more!`
        )
      } else {
        message.error(genericErrorMessage)
      }
      setStandardDownloadLoading(false)
    }
  }

  const isDownloadDisabled = () => {
    return !validateFinalSelectedFormatSettings(
      mode,
      selectedFormatSettings,
      selectedVideoSubtitles,
      notificationEmail
    )
  }

  const handleFastDownload = async () => {
    // block iPhone and non-Safari
    if (isDeviceIPhone && !isBrowserSafari) {
      message.error(`Unsupported browser, please use Safari on your iPhone.`)
      return
    }
    setFastDownloadLoading(true)
    try {
      message.info(`Preparing your download - hang tight!`)
      // get it again so that it is the latest
      const payload = await downloadableVideoFormats(videoUrl)
      const _fastDownloadPayloads = getFastDownloadPayloads(
        payload,
        mode,
        selectedFormatSettings,
        selectedVideoSubtitles,
        notificationEmail
      )
      if (_fastDownloadPayloads.length === 0) {
        throw new Error(`Did not get any downloadUrls!`)
      }
      setFastDownloadPayloads(_fastDownloadPayloads)
    } catch (err) {
      console.error(err)
      message.error(genericErrorMessage)
    }
    setFastDownloadLoading(false)
  }

  const isFastDownloadDisabled = () => {
    return !validateSelectedFormatSettingsForFastDownload(
      mode,
      selectedFormatSettings,
      selectedVideoSubtitles,
      notificationEmail
    )
  }

  const formDisabled = standardDownloadLoading || fastDownloadLoading

  if (error) {
    return (
      <div
        className={`alert alert--warning`}
        style={{ color: `black` }}
        role="alert"
      >
        {error}
      </div>
    )
  } else if (loading || !formats) {
    return <img src={loadingSVG} alt="loading" />
  }
  const { videoUrl } = props
  return (
    <div style={{ marginTop: `2em` }} className={styles.root}>
      <a target="_blank" rel="noopener noreferrer" href={videoUrl}>
        <h2>{videoTitle}</h2>
      </a>
      <VideoThumbnail
        videoThumbnail={videoThumbnail}
        videoTitle={videoTitle}
        videoUrl={videoUrl}
      />
      <ModeSelect
        formats={formats}
        videoSubtitles={videoSubtitles}
        disabled={formDisabled}
        handleChange={handleModeChange}
        currSelectedMode={mode}
      />
      <VideoFormatFormMode
        mode={mode}
        handleMergeOutputFormatChange={handleMergeOutputFormatChange}
        formats={formats}
        selectedFormatSettings={selectedFormatSettings}
        handleSelectFormat={handleSelectFormat}
        disabled={formDisabled}
        handleAudioOutputFormatChange={handleAudioOutputFormatChange}
      />
      {shouldShowSubtitlesModes.includes(mode) && (
        <SubtitleForm
          videoSubtitles={videoSubtitles}
          selectedVideoSubtitles={selectedVideoSubtitles}
          setSelectedVideoSubtitles={setSelectedVideoSubtitles}
          disabled={formDisabled}
        />
      )}
      <NotificationEmailForm
        setNotificationEmail={setNotificationEmail}
        disabled={formDisabled}
      />
      <FastDownloadVideoButton
        btnLoading={fastDownloadLoading}
        btnDisabled={
          standardDownloadLoading ||
          isDownloadDisabled() ||
          isFastDownloadDisabled()
        }
        handleFastDownload={handleFastDownload}
      />
      <StartDownloadButton
        btnLoading={standardDownloadLoading}
        btnDisabled={fastDownloadLoading || isDownloadDisabled()}
        handleStart={handleStartStandardDownload}
      />
      <FastDownloadModal
        videoTitle={videoTitle}
        fastDownloadPayloads={fastDownloadPayloads}
        handleCloseModal={() => {
          setFastDownloadPayloads([])
        }}
      />
    </div>
  )
}

export default VideoFormatForm
