import React, { useState, useEffect } from "react"
import { Form } from "react-bootstrap"
import { videoDownloadFormatModeFrontEndText } from "../../../../../utils/text"
import {
  FormatMap,
  getPossibleFormatModes,
  VideoDownloadFormatModeFrontEnd,
} from "../utils"

const ModeSelect = (props: {
  formats: FormatMap
  videoSubtitles: VideoSubtitles | undefined
  disabled: boolean
  handleChange: (e: any) => void
  currSelectedMode: VideoDownloadFormatModeFrontEnd
}) => {
  const {
    formats,
    videoSubtitles,
    disabled,
    handleChange,
    currSelectedMode,
  } = props
  const possibleModes = getPossibleFormatModes(formats, videoSubtitles)
  if (possibleModes.length <= 1) {
    // there is no point showing a Select if there is only one mode
    return <></>
  }
  return (
    <>
      <h3 style={{ fontSize: `1.5em`, marginTop: `0.5em` }}>Options</h3>
      <Form style={{ maxWidth: 300, margin: `auto` }}>
        <Form.Group>
          <Form.Control
            as="select"
            onChange={handleChange}
            disabled={disabled}
            value={currSelectedMode}
          >
            {possibleModes.map((m) => {
              return (
                <option key={m} value={m} disabled={disabled}>
                  {videoDownloadFormatModeFrontEndText(m) ?? `Others`}
                </option>
              )
            })}
          </Form.Control>
        </Form.Group>
      </Form>
    </>
  )
}

export default ModeSelect
