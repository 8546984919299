import React from "react"
import "antd/dist/antd.css"
import "bootstrap/dist/css/bootstrap.min.css"
import "../node_modules/infima/dist/css/default-dark/default-dark.css"
import "react-bootstrap-typeahead/css/Typeahead.css"
import { IS_PRODUCTION } from "./consts"
import { LocalStateProvider } from "./contexts/localState"
import { Router } from "react-router-dom"
import { history } from "./utils/history"
import Layout from "./containers/Layout/Layout"
import { message } from "antd"

if (!IS_PRODUCTION) {
  console.warn(`DEV MODE`)
  message.warn("DEVELOPMENT MODE")
}

function App() {
  return (
    <LocalStateProvider>
      <Router history={history}>
        <Layout />
      </Router>
    </LocalStateProvider>
  )
}

export default App
