import { createBrowserHistory } from "history"

const history = createBrowserHistory()

// scrolls to top when link changed
history.listen((_location: any, _action: any) => {
  window.scrollTo(0, 0)
})

export { history }
