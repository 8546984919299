import { firebaseConsts, genericErrorMessage } from "../../consts"
import { getCurrentUSCentralUnixTime } from "../time"
import { removeDownloadID } from "./api"
import { firestore, FirestoreUnsubscribeHandler } from "./firebase"

export const subscribeDownloadID = (
  downloadID: string,
  setVideoInfo: (x: VideoDownloadFirestore) => any,
  setError: ((x: string | undefined) => any) | undefined = undefined
): FirestoreUnsubscribeHandler => {
  const unsubscribe = firestore
    .collection(firebaseConsts.firestore.downloadsCollection)
    .doc(downloadID)
    .onSnapshot(
      async (doc) => {
        try {
          if (doc.exists && doc.data()) {
            if (setError) setError(undefined)
            const data = doc.data() as VideoDownloadFirestore
            const currentTime = getCurrentUSCentralUnixTime()
            if (currentTime - data.downloadInitiationTime > 24 * 60 * 60) {
              // tell server to remove if more than 24 hours since initiation
              await removeDownloadID({ downloadID: downloadID })
            } else {
              setVideoInfo(data)
            }
          } else {
            throw new Error(`Doc does not exist or data undefined`)
          }
        } catch (err) {
          console.error(err)
          if (setError)
            setError(
              `Could not find that download--your link may be wrong, or the download may be expired.`
            )
        }
      },
      (err) => {
        console.error(err)
        if (setError) setError(genericErrorMessage)
      }
    )
  return unsubscribe
}

export type VideoDownloadFirestoreWithID = {
  downloadID: string
  videoInfo: VideoDownloadFirestore
}

/**
 * Best effort, if something fails then it will just not show!
 * @param userID
 * @param setVideoInfos
 * @param setError
 * @returns
 */
export const subscribeVideoInfosForUserID = (
  userID: string,
  setVideoInfos: (x: VideoDownloadFirestoreWithID[]) => any,
  setError: ((x: string | undefined) => any) | undefined = undefined
): FirestoreUnsubscribeHandler => {
  const docRef = firestore
    .collection(firebaseConsts.firestore.downloadsCollection)
    .where("userID", "==", userID)
  const unsubscribe = docRef.onSnapshot(
    async (querySnapshot) => {
      try {
        let videoInfos: VideoDownloadFirestoreWithID[] = []
        querySnapshot.forEach(async (doc) => {
          if (doc.exists && doc.data()) {
            // console.debug(doc)
            const data = doc.data() as VideoDownloadFirestore
            const currentTime = getCurrentUSCentralUnixTime()
            if (currentTime - data.downloadInitiationTime > 24 * 60 * 60) {
              // tell server to remove if more than 24 hours since initiation
              await removeDownloadID({ downloadID: doc.id })
            } else {
              videoInfos.push({
                downloadID: doc.id,
                videoInfo: data,
              })
            }
          }
        })
        setVideoInfos(videoInfos)
      } catch (err) {
        console.error(err)
        if (setError) setError(genericErrorMessage)
      }
    },
    (err) => {
      console.error(err)
      if (setError) setError(genericErrorMessage)
    }
  )
  return unsubscribe
}
