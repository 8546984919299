import { VideoDownloadFirestoreWithID } from "../../../utils/firebase/firestore"

export type TableContent = {
  downloadID: string
  videoTitle: string
  downloadStatus: VideoDownloadStatus
  formatSettings: VideoDownloadFormatSettings
  githubActionRunID: number | undefined
}

export const extractTableContents = (
  videoInfos: VideoDownloadFirestoreWithID[]
) => {
  const sortedVideoInfos = [...videoInfos].sort(
    (a, b) =>
      b.videoInfo.downloadInitiationTime - a.videoInfo.downloadInitiationTime
  )
  return sortedVideoInfos.map((v) => {
    const { downloadID, videoInfo } = v
    const {
      videoTitle,
      downloadStatus,
      formatSettings,
      githubActionRunID,
    } = videoInfo
    return {
      downloadID: downloadID,
      videoTitle: videoTitle,
      downloadStatus: downloadStatus,
      formatSettings: formatSettings,
      githubActionRunID: githubActionRunID,
    }
  })
}
