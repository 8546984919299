import React from "react"
import { groupBy, notUndefined } from "../../../../utils/general"
import * as yup from "yup"
import {
  isDoubleFormatSettings,
  isSingleFormatSettings,
  isAudioOnlyFormatSettings,
  isSubtitlesOnlyFormatSettings,
} from "../../../../utils/video-format-settings"
import { ytdlVideoDownloadSubtitlesSettingsText } from "../../../../utils/text"
import VideoDownloadFormatSettingsDisplay from "../../../../components/VideoDownloadFormatSettingsDisplay/VideoDownloadFormatSettingsDisplay"

// recommend at most 4 custom merged formats
const MAX_RECOMMENDED_COUNT = 4

export const shouldPatchSubtitlesIntoFormatSettingsModes: VideoDownloadFormatModeFrontEnd[] = [
  `merge`,
  `audiovideo`,
  `video`,
  `audio`,
]

export const shouldShowSubtitlesModes: VideoDownloadFormatModeFrontEnd[] = [
  `subs`,
  ...shouldPatchSubtitlesIntoFormatSettingsModes,
]

export type VideoDownloadFormatModeFrontEnd =
  | Exclude<VideoDownloadFormatMode, undefined>
  | "merge" // merge: userCustomSelected two formats to merge
  | "unknown" // equiv to undefined in VideoDownloadFormatMode
  | "subs" // only two formats

export type FormatMap = Map<
  VideoDownloadFormatModeFrontEnd,
  VideoDownloadFormatSettings[]
>

const defaultMergeVideoDownloadFormatSettingsDouble: VideoDownloadFormatSettingsDouble = {
  ytdlVideoFormatSettings: undefined,
  ytdlAudioFormatSettings: undefined,
  mergeOutputFormat: "mp4",
  reasonForTwoFormats: `userCustomSelected`,
}

const sortDescVideoDownloadFormatSettingsSingle = (
  a: VideoDownloadFormatSettingsSingle,
  b: VideoDownloadFormatSettingsSingle
) => (b.ytdlFormatSettings.fileSize ?? 0) - (a.ytdlFormatSettings.fileSize ?? 0)

/**
 * try to get the highest quality audio format by file size first
 * if none, just return the first one.
 * Note that a precondition here is that `formats` was reversed after
 * receiving it from ytdl--the highest res in ytdl is the last.
 *
 * @param formats
 * @returns
 */
const getHighestQualityAudioFormat = (
  formats: VideoDownloadFormatSettingsSingle[]
): VideoDownloadFormatSettingsSingle | undefined => {
  if (formats.length === 0) {
    return undefined
  }
  // make a copy and sort so the original array is not mutated
  const formatsSortedDesc = [...formats].sort(
    sortDescVideoDownloadFormatSettingsSingle
  )
  if (formatsSortedDesc[0].ytdlFormatSettings.fileSize !== undefined) {
    // return the largest file size
    return formatsSortedDesc[0]
  }
  // return the first one
  return formats[0]
}

/**
 * get up to maxNum highest quality formats if fileSize is available
 *
 * @param formats
 * @param maxNum
 * @returns
 */
const getHighestQualityVideoFormats = (
  formats: VideoDownloadFormatSettingsSingle[],
  maxNum: number
): VideoDownloadFormatSettingsSingle[] => {
  const formatsWithFileSize = formats.filter(
    (f) => f.ytdlFormatSettings.fileSize !== undefined
  )
  // sort Desc by file size
  const formatsWithFileSizeSortedDesc = [...formatsWithFileSize].sort(
    sortDescVideoDownloadFormatSettingsSingle
  )
  const maxNumAvailable = Math.min(formatsWithFileSizeSortedDesc.length, maxNum)
  // return the first maxNumAvailable formats
  return formatsWithFileSizeSortedDesc.slice(0, maxNumAvailable)
}

const getRecommendedFormats = (
  groupedFormats: FormatMap
): VideoDownloadFormatSettings[] => {
  const audioFormats = groupedFormats.get(`audio`) as
    | VideoDownloadFormatSettingsSingle[]
    | undefined
  if (!audioFormats) {
    // give up if no audio-only streams
    return []
  }
  const videoFormats = groupedFormats.get(`video`) as
    | VideoDownloadFormatSettingsSingle[]
    | undefined
  if (!videoFormats) {
    // give up if no video-only streams
    return []
  }
  const highestQualityAudio = getHighestQualityAudioFormat(audioFormats)
  if (!highestQualityAudio) {
    // give up if no audio-only format
    return []
  }
  // now get highest quality video formats
  const highestQualityVideos = getHighestQualityVideoFormats(
    videoFormats,
    MAX_RECOMMENDED_COUNT
  )
  // mix with the highestQualityAudio
  const recommendedFormats = highestQualityVideos.map<VideoDownloadFormatSettingsDouble>(
    (f) => {
      return {
        ytdlVideoFormatSettings: f.ytdlFormatSettings,
        ytdlAudioFormatSettings: highestQualityAudio.ytdlFormatSettings,
        mergeOutputFormat: `mp4`, // default to mp4 for now
        reasonForTwoFormats: `recommended`,
      }
    }
  )
  return recommendedFormats
}

export const getAudioOnlyFormats = (
  groupedFormats: FormatMap
): VideoDownloadFormatSettings[] => {
  // if we already have audio only streams just shove it back in
  const origAudioOnly = groupedFormats.get(`audio`) ?? []
  if (origAudioOnly.length > 0) {
    return origAudioOnly.map((f: VideoDownloadFormatSettings) => {
      const ff = f as VideoDownloadFormatSettingsSingle // we know for sure that this is single format
      const res: VideoDownloadFormatSettingsAudioOnly = {
        ytdlFormatSettings: ff.ytdlFormatSettings,
        audioOutputFormat: "best",
      }
      return res
    })
  }
  // no audio only streams so we have to steal some from audiovideo
  const origAudioVideo = groupedFormats.get(`audiovideo`) ?? []
  if (origAudioVideo.length > 0) {
    // grab the final format which is usually the best
    const bestAudioVideo = origAudioVideo[
      origAudioVideo.length - 1
    ] as VideoDownloadFormatSettingsSingle // we know for sure that this is single format
    const res: VideoDownloadFormatSettingsAudioOnly = {
      ytdlFormatSettings: bestAudioVideo.ytdlFormatSettings,
      audioOutputFormat: "best",
    }
    return [res]
  }
  // give up
  return []
}

export const getFrontEndDownloadFormatSettings = (
  formats: YtdlVideoDownloadFormatSettings[]
): FormatMap => {
  // wrap the formats into VideoDownloadFormatSettings
  const formatSettings: VideoDownloadFormatSettings[] = formats
    .map((f) => {
      return { ytdlFormatSettings: f }
    })
    .reverse() // reverse so that the highest qualities are first
  // group the formats
  const groupedFormats: FormatMap = groupBy(
    formatSettings,
    (x: VideoDownloadFormatSettings) => {
      const xx = x as VideoDownloadFormatSettingsSingle // we know for sure that this is single format
      return xx.ytdlFormatSettings.mode ?? "unknown"
    }
  )
  // (1) now add in recommended formats in `audiovideo`
  const recommendedFormats = getRecommendedFormats(groupedFormats)
  const newAudioVideos = [
    ...recommendedFormats,
    ...(groupedFormats.get(`audiovideo`) ?? []),
  ]
  // (2) deal with audio formats
  const newAudioFormats = getAudioOnlyFormats(groupedFormats)

  if (newAudioVideos.length > 0)
    groupedFormats.set(`audiovideo`, newAudioVideos)
  if (newAudioFormats.length > 0) groupedFormats.set(`audio`, newAudioFormats)
  return groupedFormats
}

const videoDownloadFormatSettingsIsUserCustomSelected = (
  x: VideoDownloadFormatSettings | undefined
): x is VideoDownloadFormatSettingsDouble => {
  return (
    x !== undefined &&
    isDoubleFormatSettings(x) &&
    x.reasonForTwoFormats === `userCustomSelected`
  )
}

/**
 * For merge mode,
 * based on previously `selectedFormatSettings`, add in the newly selected `format`
 *
 * @param selectedFormatSettings
 * @param format
 * @returns
 */
export const getNewSelectedFormatSettingsForMergeMode = (
  selectedFormatSettings: VideoDownloadFormatSettings | undefined,
  format: VideoDownloadFormatSettings
): VideoDownloadFormatSettings => {
  // retain the old settings if it is already in merge mode
  const oldSelectedFormatSettings: VideoDownloadFormatSettingsDouble = videoDownloadFormatSettingsIsUserCustomSelected(
    selectedFormatSettings
  )
    ? selectedFormatSettings
    : defaultMergeVideoDownloadFormatSettingsDouble
  if (isSingleFormatSettings(format)) {
    // video
    // health check again...
    if (format.ytdlFormatSettings.mode !== `video`)
      throw new Error(`Single format should be video`)
    const newSelectedFormatSettings = {
      ...oldSelectedFormatSettings,
      ytdlVideoFormatSettings: format.ytdlFormatSettings,
    }
    return newSelectedFormatSettings
  } else if (isAudioOnlyFormatSettings(format)) {
    // audio
    const newSelectedFormatSettings = {
      ...oldSelectedFormatSettings,
      ytdlAudioFormatSettings: format.ytdlFormatSettings,
    }
    return newSelectedFormatSettings
  }
  throw new Error(`Format ${format} unsupported`)
}

const validateSelectedFormatSettings = (
  selectedFormatSettings: VideoDownloadFormatSettings | undefined
): boolean => {
  if (selectedFormatSettings === undefined) return false
  else if (
    isSingleFormatSettings(selectedFormatSettings) ||
    isAudioOnlyFormatSettings(selectedFormatSettings) ||
    isSubtitlesOnlyFormatSettings(selectedFormatSettings)
  ) {
    // always OK
    return true
  }
  // check if both fields are not undefined
  return (
    selectedFormatSettings.ytdlAudioFormatSettings !== undefined &&
    selectedFormatSettings.ytdlVideoFormatSettings !== undefined
  )
}

export const getFinalSelectedFormatSettings = (
  mode: VideoDownloadFormatModeFrontEnd,
  selectedFormatSettings: VideoDownloadFormatSettings | undefined,
  selectedVideoSubtitles: YtdlVideoDownloadSubtitlesSettings | undefined
): VideoDownloadFormatSettings | undefined => {
  if (mode === `subs`) {
    if (selectedVideoSubtitles !== undefined) {
      return {
        videoSubtitlesSettings: selectedVideoSubtitles,
      }
    }
    return undefined
  }
  if (
    selectedVideoSubtitles &&
    shouldPatchSubtitlesIntoFormatSettingsModes.includes(mode) &&
    selectedFormatSettings
  ) {
    // patch subtitles
    return {
      ...selectedFormatSettings,
      videoSubtitlesSettings: selectedVideoSubtitles,
    }
  }
  return selectedFormatSettings
}

export const validateFinalSelectedFormatSettings = (
  mode: VideoDownloadFormatModeFrontEnd,
  selectedFormatSettings: VideoDownloadFormatSettings | undefined,
  selectedVideoSubtitles: YtdlVideoDownloadSubtitlesSettings | undefined,
  notificationEmail: string
) => {
  const finalSelectedFormatSettings = getFinalSelectedFormatSettings(
    mode,
    selectedFormatSettings,
    selectedVideoSubtitles
  )
  return (
    validateSelectedFormatSettings(finalSelectedFormatSettings) &&
    yup.string().email().isValidSync(notificationEmail)
  )
}

export const getNewSelectedFormatSettingsOnMergeOutputFormatChange = (
  mode: VideoDownloadFormatModeFrontEnd,
  selectedFormatSettings: VideoDownloadFormatSettings | undefined,
  mergeOutputFormat: YtdlMergeOutputFormat
): VideoDownloadFormatSettings => {
  if (mode !== `merge`) {
    throw new Error(`Can only change mergeOutputFormat in merge mode`)
  }
  if (videoDownloadFormatSettingsIsUserCustomSelected(selectedFormatSettings)) {
    return {
      ...selectedFormatSettings,
      mergeOutputFormat: mergeOutputFormat,
    }
  }
  return {
    ...defaultMergeVideoDownloadFormatSettingsDouble,
    mergeOutputFormat: mergeOutputFormat,
  }
}

export const getNewSelectedFormatSettingsOnAudioOutputFormatChange = (
  mode: VideoDownloadFormatModeFrontEnd,
  selectedFormatSettings: VideoDownloadFormatSettings | undefined,
  audioOutputFormat: YtdlAudioOutputFormat,
  audioOnlyFormats: VideoDownloadFormatSettings[]
): VideoDownloadFormatSettings => {
  if (mode !== `audio`) {
    throw new Error(`Can only change audio output format in audio mode`)
  }
  if (
    selectedFormatSettings &&
    isAudioOnlyFormatSettings(selectedFormatSettings)
  ) {
    return {
      ...selectedFormatSettings,
      audioOutputFormat: audioOutputFormat,
    }
  }
  if (
    audioOnlyFormats.length === 0 ||
    !isAudioOnlyFormatSettings(audioOnlyFormats[0])
  ) {
    throw new Error(`No audio only formats!`) // should not happen
  }
  const firstAudioOnlyFormat = audioOnlyFormats[0]
  return {
    ...firstAudioOnlyFormat,
    audioOutputFormat: audioOutputFormat,
  }
}

const modeSortOrderMap: Record<VideoDownloadFormatModeFrontEnd, number> = {
  [`audiovideo`]: 0,
  [`audio`]: 1,
  [`video`]: 2,
  [`merge`]: 3,
  [`subs`]: 4,
  [`unknown`]: 5,
}

const sortByMode = (
  a: VideoDownloadFormatModeFrontEnd,
  b: VideoDownloadFormatModeFrontEnd
) => {
  return modeSortOrderMap[a] - modeSortOrderMap[b]
}

export const getPossibleFormatModes = (
  formats: FormatMap,
  videoSubtitles: VideoSubtitles | undefined
): VideoDownloadFormatModeFrontEnd[] => {
  const _modes = Array.from(formats.keys())

  if (formats.has(`video`) && formats.has(`audio`)) {
    // add in merge mode if possible, i.e. has video only and audio only streams
    _modes.push(`merge`)
  }
  if (videoSubtitles && Object.keys(videoSubtitles).length > 0) {
    _modes.push(`subs`)
  }

  const modes = [..._modes].sort(sortByMode)

  return modes
}

export const getFormatCodesFromVideoDownloadFormatSettings = (
  x: VideoDownloadFormatSettings | undefined
): string[] => {
  if (x === undefined) return []
  else if (isSingleFormatSettings(x)) {
    return [x.ytdlFormatSettings.formatCode]
  } else if (isDoubleFormatSettings(x)) {
    return [
      x.ytdlAudioFormatSettings?.formatCode,
      x.ytdlVideoFormatSettings?.formatCode,
    ].filter(notUndefined)
  } else if (isAudioOnlyFormatSettings(x)) {
    return [x.ytdlFormatSettings.formatCode]
  }
  return []
}

export const prepareDownloadRequest = (
  mode: VideoDownloadFormatModeFrontEnd,
  selectedFormatSettings: VideoDownloadFormatSettings | undefined,
  selectedVideoSubtitles: YtdlVideoDownloadSubtitlesSettings | undefined,
  notificationEmail: string,
  videoTitle: string | undefined,
  videoThumbnail: string | undefined,
  videoUrl: string,
  userID: string | undefined
): DownloadVideoRequest => {
  const formatSettings = getFinalSelectedFormatSettings(
    mode,
    selectedFormatSettings,
    selectedVideoSubtitles
  )
  if (
    !videoTitle ||
    !videoThumbnail ||
    !formatSettings ||
    !validateFinalSelectedFormatSettings(
      mode,
      selectedFormatSettings,
      selectedVideoSubtitles,
      notificationEmail
    )
  ) {
    throw new Error(`Not all required fields are defined to download video`)
  }
  return {
    formatSettings: formatSettings,
    videoTitle: videoTitle,
    videoThumbnail: videoThumbnail,
    videoUrl: videoUrl,
    notificationEmail: notificationEmail.length ? notificationEmail : undefined,
    userID: userID,
  }
}

export const validateSelectedFormatSettingsForFastDownload = (
  mode: VideoDownloadFormatModeFrontEnd,
  selectedFormatSettings: VideoDownloadFormatSettings | undefined,
  selectedVideoSubtitles: YtdlVideoDownloadSubtitlesSettings | undefined,
  notificationEmail: string
) => {
  if (mode === `merge`) return false
  // stricter than normal
  if (
    !validateFinalSelectedFormatSettings(
      mode,
      selectedFormatSettings,
      selectedVideoSubtitles,
      notificationEmail
    )
  )
    return false
  const finalFormatSettings = getFinalSelectedFormatSettings(
    mode,
    selectedFormatSettings,
    selectedVideoSubtitles
  )
  if (!finalFormatSettings) {
    return false
  }
  if (finalFormatSettings.videoSubtitlesSettings) {
    // if subs selected and it must have url
    const subSettings = finalFormatSettings.videoSubtitlesSettings
    if (subSettings.url === undefined) {
      return false
    }
  }
  if (isDoubleFormatSettings(finalFormatSettings)) {
    return false
  } else if (isAudioOnlyFormatSettings(finalFormatSettings)) {
    // only allow `best` and audio only stream for `audio` mode
    return (
      finalFormatSettings.audioOutputFormat === `best` &&
      finalFormatSettings.ytdlFormatSettings.mode === `audio` &&
      finalFormatSettings.ytdlFormatSettings.url // have url
    )
  } else if (isSingleFormatSettings(finalFormatSettings)) {
    return finalFormatSettings.ytdlFormatSettings.url ? true : false
  } else if (isSubtitlesOnlyFormatSettings(finalFormatSettings)) {
    // already checked before but check again
    return finalFormatSettings.videoSubtitlesSettings.url ? true : false
  }
  return false
}

export type FastDownloadPayload = { url: string; component: any }

/**
 * component shows the display of the format
 */
export const getFastDownloadPayloads = (
  payload: DownloadableVideoFormatsResponse,
  mode: VideoDownloadFormatModeFrontEnd,
  selectedFormatSettings: VideoDownloadFormatSettings | undefined,
  selectedVideoSubtitles: YtdlVideoDownloadSubtitlesSettings | undefined,
  notificationEmail: string
): FastDownloadPayload[] => {
  if (
    !validateSelectedFormatSettingsForFastDownload(
      mode,
      selectedFormatSettings,
      selectedVideoSubtitles,
      notificationEmail
    )
  ) {
    throw new Error(`Validation failed`)
  }
  const formatSettings = getFinalSelectedFormatSettings(
    mode,
    selectedFormatSettings,
    selectedVideoSubtitles
  )
  if (!formatSettings) {
    throw new Error(`Unable to get final format settings`) // should not happen!
  }
  let res: FastDownloadPayload[] = []
  if (!isSubtitlesOnlyFormatSettings(formatSettings)) {
    // we can assume it passes validation now!
    // not subs, so we need a video
    const formatCodes = getFormatCodesFromVideoDownloadFormatSettings(
      formatSettings
    )
    if (formatCodes.length !== 1) {
      throw new Error(`Should have 1 format code at least!`)
    }
    const formatCode = formatCodes[0]
    const formatCodeDownloadURL = getDownloadUrlForFormatCode(
      payload,
      formatCode
    )
    res.push({
      url: formatCodeDownloadURL,
      component: (
        <VideoDownloadFormatSettingsDisplay
          videoDownloadFormatSettings={selectedFormatSettings}
          isNotFormatForm={true}
          showRecommended={false}
        />
      ),
    })
  }
  // check if there are subs
  if (formatSettings.videoSubtitlesSettings) {
    if (formatSettings.videoSubtitlesSettings.url === undefined) {
      throw new Error(`Subtitles have no URL!`)
    }
    res.push({
      url: formatSettings.videoSubtitlesSettings.url,
      component: (
        <>
          {ytdlVideoDownloadSubtitlesSettingsText(
            formatSettings.videoSubtitlesSettings
          )}
        </>
      ),
    })
  }
  return res
}

const getDownloadUrlForFormatCode = (
  payload: DownloadableVideoFormatsResponse,
  formatCode: string
): string => {
  const { ytdlFormats } = payload
  const formatsWithFormatCode = ytdlFormats.filter(
    (y) => y.formatCode === formatCode
  )
  if (formatsWithFormatCode.length !== 1) {
    throw new Error(`Should have 1 format!`)
  }
  const format = formatsWithFormatCode[0]
  const { url } = format
  if (!url) throw new Error(`URL is undefined!`)
  return url
}
