import firebase from "firebase/app"
import "firebase/firestore"
import "firebase/functions"
import "firebase/analytics"
import { firebaseConsts, IS_PRODUCTION } from "../../consts"

const firebaseApp = firebase.initializeApp({
  apiKey: "AIzaSyAqKPkjZ_Vu9zuouyEGcllLFP75QoVsrko",
  authDomain: "downloadasaur.firebaseapp.com",
  projectId: "downloadasaur",
  storageBucket: "downloadasaur.appspot.com",
  messagingSenderId: "335607703469",
  appId: "1:335607703469:web:38099e3d6cb040e2326324",
  measurementId: "G-EFNBQF34K2",
})

export const firestore = firebaseApp.firestore()
firestore.settings({ ignoreUndefinedProperties: true })

export const functions = firebaseApp.functions()
if (!IS_PRODUCTION) {
  functions.useEmulator(`localhost`, firebaseConsts.emulatorPorts.functions)
}

export type FirestoreUnsubscribeHandler = () => void
