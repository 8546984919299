import React from "react"
import { Modal } from "antd"
import { FastDownloadPayload } from "../utils"
import { isMobile, isDeviceIPhone } from "../../../../../utils/mobile-detect"
import { message } from "antd"

const FastDownloadModal = (props: {
  videoTitle: string | undefined
  fastDownloadPayloads: FastDownloadPayload[]
  handleCloseModal: () => any
}) => {
  const { videoTitle, fastDownloadPayloads, handleCloseModal } = props
  const modalVisible = fastDownloadPayloads.length > 0

  const getDownloadInstructions = () => {
    if (isDeviceIPhone) {
      // iPhone
      return `Long press the link(s) and click "Download Linked File" to download.`
    } else if (isMobile) {
      // Android and others
      return `Long press the link(s) and click "Download File" to download.`
    }
    return `Right click "Link", then "Save Link As"`
  }

  function handleCopyFileNameToClipboard() {
    const fileSafeName = videoTitle?.replace(/[^A-z0-9\s-]/gi, "_") ?? "video"
    navigator.clipboard.writeText(fileSafeName)
    message.info(`Copied file name to clipboard`)
  }

  return (
    <Modal
      title={`Fast Download${videoTitle ? `: ${videoTitle}` : ``}`}
      visible={modalVisible}
      centered
      footer={null}
      keyboard={false}
      destroyOnClose
      onCancel={handleCloseModal}
    >
      <div style={{ textAlign: `center` }}>
        {fastDownloadPayloads.map((f, i) => {
          return (
            <div style={{ marginTop: `1em`, marginBottom: `1em` }} key={i}>
              <p
                style={{ marginBottom: 0, fontSize: `1.5em`, color: "#68b9ff" }}
              >
                {getDownloadInstructions()}
              </p>

              <a
                target="_blank"
                rel="noopener noreferrer"
                href={f.url}
                style={{ fontSize: `1.5em` }}
              >
                Link
              </a>
              <div style={{ fontSize: `0.8em` }}>{f.component}</div>
            </div>
          )
        })}
        <p style={{ marginBottom: `1em` }}>
          If this does not work, please use "Standard Download".
        </p>
        <a style={{ color: "#f1c40f" }} onClick={handleCopyFileNameToClipboard}>
          Copy file name to clipboard
        </a>
      </div>
    </Modal>
  )
}

export default FastDownloadModal
