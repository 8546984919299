export const errorCodes = {
  UNKNOWN_ERROR: 1,
  FIRESTORE_GITHUB_ACTION_UPDATE_ERROR: 10,
  YOUTUBE_DL_SETUP_ERROR: 11,
  YOUTUBE_DL_RUN_ERROR: 12,
  UNKNOWN_ARGS: 20,
}

export const errorCodeMsgMap: Map<number, string | undefined> = new Map([
  [errorCodes.UNKNOWN_ERROR, undefined],
  [
    errorCodes.FIRESTORE_GITHUB_ACTION_UPDATE_ERROR,
    `Unable to initialise your download.`,
  ],
  [errorCodes.YOUTUBE_DL_SETUP_ERROR, `Unable to setup your download.`],
  [errorCodes.YOUTUBE_DL_RUN_ERROR, `Failed to run your download`],
  [errorCodes.UNKNOWN_ARGS, `Invalid download settings.`],
])

export const RateLimitExceededError = {
  code: 429,
}
