export const isSingleFormatSettings = (
  formatSettings: VideoDownloadFormatSettings
): formatSettings is VideoDownloadFormatSettingsSingle => {
  return (
    !!(formatSettings as VideoDownloadFormatSettingsSingle)
      .ytdlFormatSettings &&
    !(formatSettings as VideoDownloadFormatSettingsAudioOnly).audioOutputFormat //we have this check coz audionlycustom also has ytdlformatsettings
  )
}

export const isDoubleFormatSettings = (
  formatSettings: VideoDownloadFormatSettings
): formatSettings is VideoDownloadFormatSettingsDouble => {
  return !!(formatSettings as VideoDownloadFormatSettingsDouble)
    .mergeOutputFormat
}

export const isAudioOnlyFormatSettings = (
  formatSettings: VideoDownloadFormatSettings
): formatSettings is VideoDownloadFormatSettingsAudioOnly => {
  return !!(formatSettings as VideoDownloadFormatSettingsAudioOnly)
    .audioOutputFormat
}

export const isSubtitlesOnlyFormatSettings = (
  formatSettings: VideoDownloadFormatSettings
): formatSettings is VideoDownloadFormatSettingsSubtitlesOnly => {
  return (
    !!(formatSettings as VideoDownloadFormatSettingsSubtitlesOnly)
      .videoSubtitlesSettings &&
    !(formatSettings as VideoDownloadFormatSettingsSingle).ytdlFormatSettings &&
    !(formatSettings as VideoDownloadFormatSettingsDouble).mergeOutputFormat
  )
}
