import axios from "axios"
import { endpoints } from "../../consts"

export const expireDownloadID = async (downloadID: string) => {
  const payload: ExpireDownloadIDRequest = {
    downloadID: downloadID,
  }
  await axios({
    method: "post",
    url: endpoints.api.expireDownloadID,
    data: payload,
  })
}

export const downloadVideo = async (
  payload: DownloadVideoRequest
): Promise<DownloadVideoResponse> => {
  const res = await axios({
    method: "post",
    url: endpoints.api.downloadVideo,
    data: payload,
  })
  return res.data as DownloadVideoResponse
}

export const downloadableVideoFormats = async (
  videoUrl: string
): Promise<DownloadableVideoFormatsResponse> => {
  const payload: DownloadableVideoFormatsRequest = {
    videoUrl: videoUrl,
  }
  const res = await axios({
    method: "post",
    url: endpoints.api.downloadableVideoFormats,
    data: payload,
  })
  return res.data as DownloadableVideoFormatsResponse
}

export const getArtifact = async (
  payload: GetArtifactRequest
): Promise<GetArtifactResponse> => {
  const res = await axios({
    method: "post",
    url: endpoints.api.getArtifact,
    data: payload,
  })
  return res.data as GetArtifactResponse
}

export const removeDownloadID = async (
  payload: RemoveDownloadIDRequest
): Promise<void> => {
  await axios({
    method: "post",
    url: endpoints.api.removeDownloadID,
    data: payload,
  })
}
