import { message } from "antd"
import React, { useState } from "react"
import { Spinner } from "react-bootstrap"
import { genericErrorMessage, CLAPERTWO, DOWNLOADER_REPO } from "../../consts"
import { triggerDownload } from "../../utils/download"
import { getArtifact } from "../../utils/firebase/api"
import { notUndefined, sleep } from "../../utils/general"

const DownloadVideoButton = (props: {
  downloadID: string
  githubActionRunID: number
  videoTitle: string
  large: boolean
}) => {
  const { downloadID, githubActionRunID, videoTitle, large } = props
  const [downloadLoading, setDownloadLoading] = useState(false)

  const handleDownload = async () => {
    setDownloadLoading(true)
    try {
      // retry because time may be needed for the artifact to be reflected on the API
      const maxTries = 6
      const retryIntervalMS = 5000
      let tries = 0
      let artifactID: number | undefined = undefined
      while (tries < maxTries) {
        try {
          const res = await getArtifact({
            downloadID: downloadID,
            githubActionRunID: githubActionRunID,
          })
          artifactID = res.artifactID
          break
        } catch (err) {
          console.debug(
            `Try ${tries}/${maxTries}: Can't get artifact: ${err}. Retry after sleeping for ${retryIntervalMS} ms.`
          )
          await sleep(retryIntervalMS)
          tries++
        }
      }
      if (artifactID === undefined) {
        throw new Error(`Can't get artifact after ${maxTries} tries`)
      }
      const downloadUrl = `https://nightly.link/${CLAPERTWO}/${DOWNLOADER_REPO}/actions/artifacts/${artifactID}.zip`
      await triggerDownload(downloadUrl, `${videoTitle}.zip`)
      message.info(`Your download is starting - hang tight!...`)
    } catch (err) {
      console.error(err)
      message.error(genericErrorMessage)
    }

    setTimeout(() => setDownloadLoading(false), 5000)
  }

  if (downloadLoading) {
    return (
      <button
        className={[
          "button disabled button--success",
          large ? `button--lg` : undefined,
        ]
          .filter(notUndefined)
          .join(` `)}
      >
        <Spinner
          as="span"
          role="status"
          aria-hidden="true"
          size="sm"
          animation="border"
          variant="light"
          style={{ verticalAlign: `middle` }}
        />{" "}
        Loading...
      </button>
    )
  } else {
    return (
      <button
        onClick={() => {
          handleDownload()
        }}
        className={["button button--success", large ? `button--lg` : undefined]
          .filter(notUndefined)
          .join(` `)}
      >
        Download
      </button>
    )
  }
}

export default DownloadVideoButton
