import { useEffect, useState } from "react"
import { Navbar } from "react-bootstrap"
import { Link } from "react-router-dom"
import logo from "../../../assets/logo192.png"
import styles from "./Header.module.css"
import { DOWNLOADASAUR } from "../../../consts"

const Header = () => {
  return (
    <Navbar variant="dark" expand="sm">
      <Link to={"/"}>
        <Navbar.Brand className={styles.logoWrapper}>
          <img src={logo} alt={DOWNLOADASAUR} className={styles.logo} />
          <span className={styles.logoText}>{DOWNLOADASAUR}</span>
        </Navbar.Brand>
      </Link>
    </Navbar>
  )
}

export default Header
