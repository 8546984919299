import React from "react"
import Header from "./Header/Header"
import AppContent from "./AppContent/AppContent"
import Footer from "./Footer/Footer"

const Layout = () => {
  return (
    <div>
      <Header />
      <div
        style={{
          minHeight: "85vh",
          backgroundColor: "black",
          color: "white",
        }}
      >
        <AppContent />
      </div>
      <Footer />
    </div>
  )
}

export default Layout
