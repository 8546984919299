import React from "react"
import { Link } from "react-router-dom"
import Separator from "../../../components/Separator/Separator"
import {
  currentYear,
  endpoints,
  DOWNLOADASAUR,
  CONTACT_EMAIL,
  CLAPERONE,
} from "../../../consts"

const Footer = () => {
  return (
    <footer
      className="footer"
      style={{
        color: "white",
        backgroundColor: `var(--ifm-color-gray-900)`,
        boxShadow: `0 50vh 0 50vh var(--ifm-color-gray-900)`,
      }}
    >
      <div className="container container--fluid text--center">
        <div className="footer__links">
          Copyright © {currentYear} {CLAPERONE} Inc.{" "}
        </div>

        <div className="footer__links">
          <a
            className="footer__link-item"
            target="_blank"
            rel="noopener noreferrer"
            href={`mailto:${CONTACT_EMAIL}`}
          >
            Contact
          </a>
          {Separator}
          <Link
            className="footer__link-item"
            to={`${endpoints.routes.privacyPolicy}`}
          >
            Privacy Policy
          </Link>
          {Separator}
          <Link
            className="footer__link-item"
            to={`${endpoints.routes.termsOfService}`}
          >
            Terms of Service
          </Link>
          {Separator}
          <Link className="footer__link-item" to={`${endpoints.routes.faq}`}>
            FAQ
          </Link>
        </div>
      </div>
    </footer>
  )
}

export default Footer
