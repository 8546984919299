import React from "react"
import { CONTACT_EMAIL, genericErrorMessage } from "../../consts"
import { history } from "../../utils/history"

import styles from "./ErrorComponent.module.css"
type PropType = {
  error?: string
}

const defaultProps: PropType = {
  error: `Unknown error. Please try again or report a bug.`,
}

const ErrorComponent: React.FC<PropType> = (props: PropType) => {
  return (
    <div style={{ margin: "auto" }} className="container text--center">
      <h1 style={{ paddingTop: 30, marginBottom: 30, fontSize: "3rem" }}>
        {genericErrorMessage}
      </h1>
      <div className={`alert alert--danger ${styles.errorBox}`} role="alert">
        {props.error}
      </div>
      <button
        onClick={() => history.goBack()}
        className={`button button--secondary button--outline ${styles.btn}`}
      >
        Go Back
      </button>
      <a href="/">
        <button
          className={`button button--secondary button--outline ${styles.btn}`}
        >
          Go Home
        </button>
      </a>
      <a
        target="_blank"
        rel="noopener noreferrer"
        href={`mailto:${CONTACT_EMAIL}`}
      >
        <button className="button button--secondary button--outline">
          Report a Bug
        </button>
      </a>
    </div>
  )
}

ErrorComponent.defaultProps = defaultProps

export default ErrorComponent
