import React from "react"
import { Form } from "react-bootstrap"
import { YtdlMergeOutputFormats } from "../../../../../consts"
import { FormatMap, VideoDownloadFormatModeFrontEnd } from "../utils"
import VideoFormatSelectionForm from "./VideoFormatSelectionForm/VideoFormatSelectionForm"
import styles from "./VideoFormatFormMode.module.css"
import AudioOutputFormatForm from "./AudioOutputFormatForm/AudioOutputFormatForm"

const VideoFormatFormMode = (props: {
  mode: VideoDownloadFormatModeFrontEnd
  handleMergeOutputFormatChange: (e: any) => any
  formats: FormatMap | undefined
  selectedFormatSettings: VideoDownloadFormatSettings | undefined
  handleSelectFormat: (format: VideoDownloadFormatSettings) => any
  handleAudioOutputFormatChange: (e: any) => any
  disabled: boolean
}) => {
  const {
    mode,
    handleMergeOutputFormatChange,
    handleAudioOutputFormatChange,
    formats,
    selectedFormatSettings,
    handleSelectFormat,
    disabled,
  } = props

  const WrappedVideoFormatSelectionForm = (props: {
    formats: VideoDownloadFormatSettings[]
  }) => {
    return (
      <VideoFormatSelectionForm
        mode={mode}
        formats={props.formats}
        selectedFormatSettings={selectedFormatSettings}
        handleSelectFormat={handleSelectFormat}
        disabled={disabled}
      />
    )
  }
  if (formats === undefined || mode === `subs`) return <></>
  else if (mode !== `merge`) {
    return (
      <>
        <WrappedVideoFormatSelectionForm formats={formats.get(mode) ?? []} />
        {mode === `audio` && (
          <AudioOutputFormatForm
            handleAudioOutputFormatChange={handleAudioOutputFormatChange}
            disabled={disabled}
          />
        )}
      </>
    )
  } else {
    return (
      <span className={styles.root}>
        <h4>Video Stream</h4>
        <WrappedVideoFormatSelectionForm formats={formats.get(`video`) ?? []} />
        <h4 style={{ marginTop: `1em` }}>Audio Stream</h4>
        <WrappedVideoFormatSelectionForm formats={formats.get(`audio`) ?? []} />
        <h4 style={{ marginTop: `1em` }}>Output Format</h4>
        <Form style={{ maxWidth: 500, margin: `auto` }}>
          <Form.Group>
            <Form.Control
              as="select"
              onChange={handleMergeOutputFormatChange}
              disabled={disabled}
              defaultValue={YtdlMergeOutputFormats[0]}
            >
              {YtdlMergeOutputFormats.map((f, i) => {
                return (
                  <option key={i} value={f}>
                    {f}
                  </option>
                )
              })}
            </Form.Control>
          </Form.Group>
        </Form>
      </span>
    )
  }
}

export default VideoFormatFormMode
