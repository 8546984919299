import React from "react"
import { Link } from "react-router-dom"
import { DOWNLOADASAUR } from "../../consts"

export type FaqItem = {
  question: string
  answer: JSX.Element
}
const youtubeDlLink = (
  <a target="_blank" rel="noopener noreferrer" href="https://youtube-dl.org/">
    youtube-dl
  </a>
)
export const FaqPage = () => {
  const faqItems: FaqItem[] = [
    {
      //i think have this first coz the main reason ppl will come to the faq is prolly to figure out why their download failed
      question: `Why did my download fail?`,
      answer: (
        <div>
          You may have received a download error for many reasons, some being
          our fault, some being yours. For example, there are certain audio and
          video format combinations that simply do not work together, and/or
          with the output merge format you selected. If this happens, just try a
          different combination, and perhaps research if your desired
          combination/setting is allowed by {youtubeDlLink}. Further,
          downloading some large files may fail, we are currently investigating
          this issue.
        </div>
      ),
    },
    {
      question: `What video sites are supported by ${DOWNLOADASAUR}?`,
      answer: (
        <div>
          Many! Just <Link to="/">try the video URL</Link>. See{" "}
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://ytdl-org.github.io/youtube-dl/supportedsites.html"
          >
            here
          </a>{" "}
          for a full list.
        </div>
      ),
    },
    {
      question: `How long are downloaded videos available on ${DOWNLOADASAUR}?`,
      answer: <div>Up to 24 hours.</div>,
    },

    {
      question: `How does ${DOWNLOADASAUR} work?`,
      answer: (
        <div>
          We use {youtubeDlLink} behind the scenes. A lot of issues with this
          service directly stem from issues with {youtubeDlLink}.
        </div>
      ),
    },
  ]

  return (
    <div style={{ padding: "32px 0" }}>
      <div className="card" style={{ maxWidth: "80%", margin: "auto" }}>
        <div className="card__header" style={{ textAlign: "center" }}>
          <h3 style={{ fontSize: "2rem", marginBottom: 24 }}>FAQ</h3>
        </div>
        <div className="card__body" style={{ margin: "0 16px" }}>
          {faqItems.map((f, i) => {
            return (
              <div
                key={i}
                className="card"
                style={{
                  backgroundColor: `var(--ifm-color-gray-800)`,
                  padding: 12,
                  marginBottom: 24,
                }}
              >
                <h4 style={{ marginBottom: 8 }}>{f.question}</h4>
                {f.answer}
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}

export default FaqPage
