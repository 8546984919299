export const groupBy = <T, K>(
  list: T[],
  keyGetter: (input: T) => K
): Map<K, T[]> => {
  const map = new Map()
  list.forEach((item) => {
    const key = keyGetter(item)
    const collection = map.get(key)
    if (!collection) {
      map.set(key, [item])
    } else {
      collection.push(item)
    }
  })
  return map
}

export const notUndefined = <T>(x: T | undefined): x is T => {
  return x !== undefined
}

export const sleep = async (ms: number) => {
  return new Promise((resolve) => setTimeout(resolve, ms))
}
